import * as React from "react";
import {FC, useCallback} from "react";
import {
    Identifier,
    ReferenceInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    useCreateController,
    useNotify,
    useRefresh,
    useTranslate
} from "react-admin";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {PersonTitle} from "../PersonTitle";
import Button from "@mui/material/Button";
import {yupResolver} from "@hookform/resolvers/yup";
import {schema} from "../../contact/validation";
import {contactTypeChoices} from "../../contsatns/common";

interface Props {
    onClose: () => void
    open: boolean
    personId: Identifier
}

const resolver = yupResolver(schema);
const renderChildren: React.FC = ({children}) => <>{children}</>;


export const ContactsCreateDialog: FC<Props> = (props) => {
    const {record, save,} = useCreateController({
        resource: 'Contact',
        redirect: false,
        record: {
            person: {
                id: props.personId
            }
        }
    });
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleSubmit = useCallback((values) => {
        save!(values, {
            onSuccess: (data, variables) => {
                props.onClose();
                refresh()
                notify('ra.notification.created', {
                    type: 'info',
                    messageArgs: {smart_count: 1},
                });
            }
        })
    }, []);

    return <Dialog
        open={props.open}
        onClose={props.onClose}>
        <SimpleForm
            resolver={resolver}
            reValidateMode="onChange"
            record={record}
            toolbar={false}
            component={renderChildren}
            onSubmit={handleSubmit}
        >
            <DialogTitle>{translate('resources.contacts.action.create')}</DialogTitle>
            <DialogContent>
                <ReferenceInput
                    source="person.id"
                    reference="Person"
                    label="resources.contacts.fields.person">
                    <SelectInput
                        disabled
                        fullWidth
                        optionText={PersonTitle}/>
                </ReferenceInput>
                <SelectInput
                    source="contactType"
                    label="resources.contacts.fields.contactType"
                    choices={contactTypeChoices}
                    optionText="label"
                    optionValue="value"
                    fullWidth
                />
                <TextInput label="resources.contacts.fields.value" source="value" fullWidth/>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{translate('resources.contacts.action.cancel')}</Button>
                <SaveButton
                    label="resources.contacts.action.save"
                    variant="text"
                />
            </DialogActions>
        </SimpleForm>
    </Dialog>
}