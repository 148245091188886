import * as yup from "yup";
import {ActionType} from "../api/actionType/ActionType";
import {yupResolver} from "@hookform/resolvers/yup";
import {Family} from "../api/family/Family";

export const createSchema = yupResolver(yup
    .object<Family>()
    .shape({
        label: yup.string().required(),
        status: yup.object({
            id: yup.string().required(),
        }),
        // people: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
    })
    .required()
);
