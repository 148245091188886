import GroupIcon from '@mui/icons-material/Group';
import {UserList} from "./UserList";
import {UserCreate} from "./UserCreate";
import {UserEdit} from "./UserEdit";
import {UserShow} from "./UserShow";

export default {
    name: 'User',
    icon: GroupIcon,
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
    show: UserShow,
    options: {
        label: 'resources.users.name'
    },
};