import * as React from "react";
import {FC} from "react";
import {SaveButton, SimpleForm, useNotify, useTranslate} from "react-admin";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {updateDocSchema} from "../../doc/validation";
import {DocEditFields} from "../../doc/DocEditFields";
import {useUpdateDoc} from "../../doc/useUpdateDoc";

interface Props {
    onClose: () => void
    isOpen: boolean
    id: string
}

const renderChildren: React.FC = ({children}) => <>{children}</>;

export const DocUpdateDialog: FC<Props> = (props) => {
    const translate = useTranslate();
    const notify = useNotify();

    const {handleSubmit, record} = useUpdateDoc({
        id: props.id,
        onSuccess: (data) => {
            props.onClose();
            notify('ra.notification.updated', {
                type: 'info',
                messageArgs: {smart_count: 1},
            });
        }
    })

    return <Dialog
        open={props.isOpen}
        onClose={props.onClose}>
        <SimpleForm
            record={record}
            resolver={updateDocSchema}
            reValidateMode="onChange"
            toolbar={false}
            component={renderChildren}
            onSubmit={handleSubmit}>
            <DialogTitle>{translate('resources.contacts.action.edit')}</DialogTitle>
            <DialogContent>
                <DocEditFields personId={props.id} isDialog/>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{translate('resources.contacts.action.cancel')}</Button>
                <SaveButton
                    label="resources.contacts.action.save" //todo translate this file, move localizations to common
                    variant="text"
                />
            </DialogActions>
        </SimpleForm>
    </Dialog>
}