import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import {DistrictList} from "./DistrictList";
import {DistrictCreate} from "./DistrictCreate";
import {DistrictEdit} from "./DistrictEdit";
import {DistrictShow} from "./DistrictShow";

export default {
    name: 'District',
    icon: OtherHousesIcon,
    list: DistrictList,
    create: DistrictCreate,
    edit: DistrictEdit,
    show: DistrictShow,
    options: {
        label: 'resources.districts.name'
    },
};
