import * as React from "react";

import {
    Create,
    SimpleForm,
    CreateProps,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    TextInput,
} from "react-admin";

import {CommonTitle} from "../common/CommonTitle";
import {DistrictTitle} from "../district/DistrictTitle";
import {PersonAddressTitle} from "../personAddress/PersonAddressTitle";
import {StreetTitle} from "../street/StreetTitle";
import {createSchema} from "./validation";
import {City} from "../api/city/City";
import {createTransformFn} from "../util/createTransformFn";

const transform = createTransformFn<City>(['districts', 'personAddresses', 'streets']);
export const CityCreate = (props: CreateProps): React.ReactElement => {
    return (
        <Create {...props} transform={transform} redirect={'list'}>
            <SimpleForm resolver={createSchema}>
                <ReferenceInput source="common.id" reference="Common">
                    <SelectInput optionText={CommonTitle} label="resources.cities.fields.common"/>
                </ReferenceInput>
                <ReferenceArrayInput
                    source="districts"
                    reference="District"
                >
                    <SelectArrayInput
                        label="resources.cities.fields.districts"
                        optionText={DistrictTitle}
                        defaultValue={[]}
                    />
                </ReferenceArrayInput>
                <TextInput label="resources.cities.fields.label" source="label"/>
                <ReferenceArrayInput
                    source="personAddresses"
                    reference="PersonAddress">
                    <SelectArrayInput
                        label="resources.cities.fields.Email"
                        optionText={PersonAddressTitle}
                        defaultValue={[]}
                    />
                </ReferenceArrayInput>
                <TextInput label="Slug" source="slug"/>
                <ReferenceArrayInput
                    source="streets"
                    reference="Street">
                    <SelectArrayInput
                        label="resources.cities.fields.personAddresses"
                        optionText={StreetTitle}
                        defaultValue={[]}
                    />
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    );
};
