import {
    DateField,
    FunctionField,
    Loading, ReferenceField,
    ReferenceManyField,
    SimpleShowLayout, SingleFieldList,
    TextField,
    useGetOne,
    useRecordContext,
    useTranslate
} from "react-admin";
import {Person} from "../../api/person/Person";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";
import {Chip} from "@mui/material";
import {FAMILYSTATUS_TITLE_FIELD} from "../../familyStatus/FamilyStatusTitle";


function renderFamilyMember(record: Person) {
    return <Chip
        sx={{my: 0.5, mr: 1}}
        clickable
        label={`${record.lastName} ${record.firstName}`}/>;
}
export const PersonEditAside = () => {
    const translate = useTranslate();
    const record = useRecordContext<Person>();

    const {data: family, isLoading, error} = useGetOne('Family', {
            id: record?.family?.id,
        },
        {
            enabled: !!record?.family?.id,
        });
    if (isLoading) {
        return <Loading/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    return (
        <Box sx={{width: '400px', mt: '0', ml: '1em'}}>
            {
                record && <>
                    <SimpleShowLayout record={family}>
                        <Typography variant="h6">{translate('resources.people.edit.asideTitle')}</Typography>
                        <TextField label="resources.families.fields.label" source="label"/>
                        <ReferenceManyField reference="Person" target="FamilyId" label="People">
                            <SingleFieldList>
                                <FunctionField render={renderFamilyMember}/>
                            </SingleFieldList>
                        </ReferenceManyField>
                        <ReferenceField
                            label="resources.families.fields.status"
                            source="status.id"
                            reference="FamilyStatus"
                        >
                            <TextField source={FAMILYSTATUS_TITLE_FIELD} />
                        </ReferenceField>
                        <DateField label={'resources.families.fields.createdAt'} source="createdAt"/>
                    </SimpleShowLayout>
                </>
            }
        </Box>
    );
};
