import * as React from "react";

import {
  Show,
  SimpleShowLayout,
  ShowProps,
  ReferenceField,
  TextField,
  DateField,
  ReferenceManyField,
  Datagrid,
} from "react-admin";

import { CITY_TITLE_FIELD } from "./CityTitle";
import { COMMON_TITLE_FIELD } from "../common/CommonTitle";
import { COUNTRY_TITLE_FIELD } from "../country/CountryTitle";
import { DISTRICT_TITLE_FIELD } from "../district/DistrictTitle";
import { PERSON_TITLE_FIELD } from "../person/PersonTitle";
import { STATE_TITLE_FIELD } from "../state/StateTitle";
import { STREET_TITLE_FIELD } from "../street/StreetTitle";

export const CityShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ReferenceField label="resources.cities.fields.common" source="common.id" reference="Common">
          <TextField source={COMMON_TITLE_FIELD} />
        </ReferenceField>
        <DateField source="createdAt" label="resources.cities.fields.createdAt" />
        <TextField label="ID" source="id" />
        <TextField label="resources.cities.fields.label" source="label" />
        <TextField label="Slug" source="slug" />
        <DateField source="updatedAt" label="resources.cities.fields.updatedAt" />
        <ReferenceManyField
          reference="District"
          target="CityId"
          label="resources.cities.fields.districts"
        >
          <Datagrid rowClick="show">
            <ReferenceField label="City" source="city.id" reference="City">
              <TextField source={CITY_TITLE_FIELD}  />
            </ReferenceField>
            <DateField source="createdAt" label="Created At" />
            <TextField label="ID" source="id" />
            <TextField label="Label" source="label" />
            <TextField label="Slug" source="slug" />
            <DateField source="updatedAt" label="Updated At" />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          reference="PersonAddress"
          target="CityId"
          label="resources.cities.fields.personAddresses"
        >
          <Datagrid rowClick="show">
            <TextField label="Appartment" source="appartment" />
            <ReferenceField label="City" source="city.id" reference="City">
              <TextField source={CITY_TITLE_FIELD} />
            </ReferenceField>
            <ReferenceField
              label="Common"
              source="common.id"
              reference="Common"
            >
              <TextField source={COMMON_TITLE_FIELD} />
            </ReferenceField>
            <ReferenceField
              label="Country"
              source="country.id"
              reference="Country"
            >
              <TextField source={COUNTRY_TITLE_FIELD} />
            </ReferenceField>
            <DateField source="createdAt" label="Created At" />
            <ReferenceField
              label="District"
              source="district.id"
              reference="District"
            >
              <TextField source={DISTRICT_TITLE_FIELD} />
            </ReferenceField>
            <TextField label="End Date" source="endDate" />
            <TextField label="House" source="house" />
            <TextField label="ID" source="id" />
            <ReferenceField
              label="Person"
              source="person.id"
              reference="Person"
            >
              <TextField source={PERSON_TITLE_FIELD} />
            </ReferenceField>
            <TextField label="Start Date" source="startDate" />
            <ReferenceField label="State" source="state.id" reference="State">
              <TextField source={STATE_TITLE_FIELD} />
            </ReferenceField>
            <ReferenceField
              label="Street"
              source="street.id"
              reference="Street"
            >
              <TextField source={STREET_TITLE_FIELD} />
            </ReferenceField>
            <DateField source="updatedAt" label="Updated At" />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField reference="Street" target="CityId" label="resources.cities.fields.street">
          <Datagrid rowClick="show">
            <ReferenceField label="City" source="city.id" reference="City">
              <TextField source={CITY_TITLE_FIELD} />
            </ReferenceField>
            <DateField source="createdAt" label="Created At" />
            <TextField label="ID" source="id" />
            <TextField label="Label" source="label" />
            <TextField label="Slug" source="slug" />
            <DateField source="updatedAt" label="Updated At" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
