import * as yup from "yup";
import {Person} from "../api/person/Person";
import {yupResolver} from "@hookform/resolvers/yup";

export const createSchema = yupResolver(yup
    .object<Person>()
    .shape({
        lastName: yup.string().required(),
        firstName: yup.string().required(),
        familyRole: yup.string().required(),
        family: yup.object({
            id: yup.string().required(),
        }),
    })
    .required()
);
