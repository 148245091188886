import * as yup from "yup";
import {ActionType} from "../api/actionType/ActionType";
import {yupResolver} from "@hookform/resolvers/yup";

export const createSchema = yupResolver(yup
    .object<ActionType>()
    .shape({
        // actions: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
        label: yup.string().required(),
        slug: yup.string().required(),
    })
    .required()
);
