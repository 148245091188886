import * as React from 'react';
import {
    AppBar,
    TitlePortal,
    Logout,
    UserMenu,
    useTranslate,
    useUserMenu,
    ToggleThemeButton,
    InspectorButton,
} from 'react-admin';
import {Link} from 'react-router-dom';
import {
    Box,
    MenuItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    Theme,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import {darkTheme, lightTheme} from '../theme/theme';

import Logo from './Logo';

const ConfigurationMenu = React.forwardRef((props, ref) => {
    const {onClose} = useUserMenu();
    const translate = useTranslate();
    return (
        <MenuItem
            {...props}
            onClick={onClose}
        >
            <ListItemIcon>
                <SettingsIcon fontSize="small"/>
            </ListItemIcon>
        </MenuItem>
    );
});
const CustomUserMenu = () => (
    <UserMenu>
        <ConfigurationMenu/>
        <Logout/>
    </UserMenu>
);
const CustomAppBar = () => {
    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );
    return (
        <AppBar color="secondary" elevation={1} userMenu={<CustomUserMenu/>}>
            <TitlePortal/>
            {isLargeEnough && <Logo/>}
            {isLargeEnough && <Box component="span" sx={{flex: 1}}/>}
            <ToggleThemeButton lightTheme={lightTheme} darkTheme={darkTheme} />
            <InspectorButton/>
        </AppBar>
    );
};

export default CustomAppBar;
