import * as React from "react";

import {
  Create,
  SimpleForm,
  CreateProps,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { City } from "../api/city/City";

import { CityTitle } from "../city/CityTitle";
import { PersonAddressTitle } from "../personAddress/PersonAddressTitle";
import { StateTitle } from "../state/StateTitle";
import {createTransformFn} from "../util/createTransformFn";
import {createSchema} from "./validation";
import {Common} from "../api/common/Common";

const transform = createTransformFn<Common>(['cities', 'personAddresses']);
export const CommonCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props} transform={transform} redirect={'list'}>
      <SimpleForm resolver={createSchema}>
        <ReferenceArrayInput
          source="cities"
          reference="City"
          parse={(value: any) => value && value.map((v: any) => ({ id: v }))}
          format={(value: any) => value && value.map((v: any) => v.id)}
        >
          <SelectArrayInput optionText={CityTitle} label="resources.commons.fields.cities" />
        </ReferenceArrayInput>
        <TextInput label="resources.commons.fields.label" source="label" />
        <ReferenceArrayInput
          source="personAddresses"
          reference="PersonAddress"
          parse={(value: any) => value && value.map((v: any) => ({ id: v }))}
          format={(value: any) => value && value.map((v: any) => v.id)}
        >
          <SelectArrayInput optionText={PersonAddressTitle} label="resources.commons.fields.personAddresses" />
        </ReferenceArrayInput>
        <TextInput label="Slug" source="slug" />
        <ReferenceInput source="state.id" reference="State" >
          <SelectInput optionText={StateTitle} label="resources.commons.fields.state" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
