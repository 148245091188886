import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {useTranslate} from "react-admin";
import {Children} from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    isMobile: boolean;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: props.isMobile ? 1 : 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const BasicTabs: React.FC<{ isMobile: boolean }> = ({children, isMobile}) => {
    const [value, setValue] = React.useState(0);
    const translate = useTranslate()

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={translate('resources.people.fields.docs')} {...a11yProps(0)} />
                    <Tab label={translate('resources.people.fields.photos')} {...a11yProps(1)} />
                </Tabs>
            </Box>
            {Children.map(children, (child, index) =>
                <CustomTabPanel value={value} index={index} isMobile={isMobile}>
                    {child}
                </CustomTabPanel>
            )}
        </Box>
    );
}