import * as React from "react";
import {
  List,
  Datagrid,
  ListProps,
  DateField,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField
} from "react-admin";
import Pagination from "../Components/Pagination";

export const CountryList = (props: ListProps): React.ReactElement => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Countries"}
      perPage={50}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show">
        <DateField source="createdAt" label="resources.countries.fields.personAddresses" />
        <TextField label="ID" source="id" />
        <TextField label="resources.countries.fields.label" source="label" />
        <TextField label="Slug" source="slug" />
        <TextField label="resources.countries.fields.states" source="states" />
        {/*<ReferenceArrayField reference="State" source="state" />*/}
        <DateField source="updatedAt" label="resources.countries.fields.updatedAt" />
      </Datagrid>
    </List>
  );
};
