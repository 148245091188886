import AttachmentIcon from '@mui/icons-material/Attachment';
import {FileList} from "./FileList";
import {FileCreate} from "./FileCreate";
import {FileShow} from "./FileShow";

export default {
    name: 'File',
    icon: AttachmentIcon,
    list: FileList,
    create: FileCreate,
    show: FileShow,
    options: {
        label: 'resources.files.name'
    },
};
