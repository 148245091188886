import {useNotify} from "react-admin";
import {useMemo} from "react";
import {MAX_FILE_SIZE} from "../contsatns/common";
import {FileRejection} from "react-dropzone";

export const useFileSizeValidation = () => {
    const notify = useNotify();
    return useMemo(() => ({
        maxSize: MAX_FILE_SIZE,
        accept: 'image/*',
        options: {
            onDropRejected: (e: FileRejection[]) => {
                e.forEach((fileRejection) => {
                    fileRejection.errors.forEach((error) => {
                        notify(`ra.notification.${error.code}`, {
                            type: 'warning',
                        })
                    })
                });
            }
        }
    }), [notify]);
}
