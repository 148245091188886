import * as React from "react";

import {
  Create,
  SimpleForm,
  CreateProps,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";

import { PersonAddressTitle } from "../personAddress/PersonAddressTitle";
import { StateTitle } from "../state/StateTitle";
import {createTransformFn} from "../util/createTransformFn";
import {Country} from "../api/country/Country";
import {createSchema} from "./validation";

const transform = createTransformFn<Country>(['personAddresses', 'states']);
export const CountryCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props} transform={transform} redirect={'list'}>
      <SimpleForm resolver={createSchema}>
        <TextInput label="resources.countries.fields.label" source="label" />
        <ReferenceArrayInput
          source="personAddresses"
          reference="PersonAddress"
        >
          <SelectArrayInput optionText={PersonAddressTitle} label="resources.countries.fields.createdAt"/>
        </ReferenceArrayInput>
        <TextInput label="Slug" source="slug" />
        <ReferenceArrayInput
          source="states"
          reference="State"
        >
          <SelectArrayInput optionText={StateTitle} label="resources.countries.fields.states"/>
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
