import * as React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Box, Button, InputAdornment} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {TextInput, useTranslate} from 'react-admin';
import {useCallback} from "react";

interface Props {
    onSubmit: (value: string) => void;
    searchQuery: string;
}

type OnSubmitParams = { query: string };
export const ListFilter = (props: Props) => {

    const form = useForm<OnSubmitParams>({
        defaultValues: {
            query: props.searchQuery,
        },
    });

    const onSubmit = (values: OnSubmitParams) => {
        props.onSubmit(values.query);
    };
    const translate = useTranslate();
    const handleChange = useCallback((ev: any) => {
        if (!ev) {
            props.onSubmit("");
        }
    }, [props.onSubmit]);
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Box display="flex" alignItems="flex-start">
                    <Box component="span" mr={2} mb={0}>
                        {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                        <TextInput
                            resettable
                            autoFocus
                            helperText={false}
                            source="query"
                            label="ra.action.search"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <SearchIcon color="disabled"/>
                                </InputAdornment>,
                            }}
                            onChange={handleChange}/>
                    </Box>
                    <Box component="span" mr={2}>
                        <Button variant="outlined" color="primary" type="submit">
                            {translate('ra.action.btn_search')}
                        </Button>
                    </Box>
                </Box>
            </form>
        </FormProvider>
    );
};
