import * as yup from "yup";
import {City} from "../api/city/City";
import {yupResolver} from "@hookform/resolvers/yup";

export const createSchema = yupResolver(yup
    .object<City>()
    .shape({
        label: yup.string().required(),
        slug: yup.string().required(),
    })
    .required()
);
