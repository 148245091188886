import * as React from "react";

import {CreateProps, SimpleForm, Title, useCreate, useRedirect,} from "react-admin";
import {ActionCreateFields} from "./ActionCreateFields";
import {createActionSchema} from "./validation";
import {useCreateAction} from "./useCreateAction";

export const ActionCreate = (props: CreateProps): React.ReactElement => {
    const redirect = useRedirect();

    const {handleSubmit} = useCreateAction({
        onSuccess: () => redirect('/action')
    })

    return (
        <>
            <Title title="resources.docs.create.title"/>
            <SimpleForm
                defaultValues={{date: new Date()}}
                onSubmit={handleSubmit}
                resolver={createActionSchema}>
                <ActionCreateFields/>
            </SimpleForm>
        </>
    );
};
