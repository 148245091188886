import Icon from '@mui/icons-material/Home';
import {PersonAddressList} from "./PersonAddressList";
import {PersonAddressCreate} from "./PersonAddressCreate";
import {PersonAddressEdit} from "./PersonAddressEdit";
import {PersonAddressShow} from "./PersonAddressShow";

export default {
    name: 'PersonAddress',
    icon: Icon,
    list: PersonAddressList,
    create: PersonAddressCreate,
    edit: PersonAddressEdit,
    show: PersonAddressShow,
    options: {
        label: 'resources.personAddresses.name'
    },
};