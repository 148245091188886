import * as React from "react";

import {
    AutocompleteInput,
    BooleanInput,
    DateInput,
    Edit,
    EditProps,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    useGetRecordId,
    useTranslate
} from "react-admin";
import {createSchema as schema} from "../validation";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Box from "@mui/material/Box/Box";
import {familyRolesChoices, sexChoices} from "../../contsatns/common";
import {CreateFamilyDialog} from "../../family/CreateFamilyDialog";
import {FamilyTitle} from "../../family/FamilyTitle";
import {PersonContacts} from "./PersonContacts";
import {PersonAddresses} from "./PersonAddresses";
import {Photos} from "./Photos";
import {Docs} from "./Docs";
import {BasicTabs} from "../components/Tabs";
import {Actions} from "./Actions";
import {PersonEditAside} from "../components/PersonEditAside";
import {Theme, useMediaQuery} from "@mui/material";

const Hole: React.FC = () => null;

export const PersonEdit = (props: EditProps): React.ReactElement => {
    const recordId = useGetRecordId();
    const isXsmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const translate = useTranslate();
    return (
        <Edit
            {...props}
            title={'resources.people.edit.title'}
            mutationMode="optimistic"
            aside={!isXsmall ? <PersonEditAside/> : <Hole/>}
        >
            <SimpleForm
                resolver={schema}
                mode="onBlur"
                reValidateMode="onChange"
                warnWhenUnsavedChanges>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{translate('resources.people.create.title1')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <TextInput
                            fullWidth
                            label={translate("resources.people.fields.lastName")}
                            source="lastName"/>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <TextInput
                            fullWidth
                            label={translate("resources.people.fields.firstName")}
                            source="firstName"/>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <TextInput
                            fullWidth
                            label={translate("resources.people.fields.surname")}
                            source="surname"/>
                    </Grid>
                    <Box width="100%"/>
                    <Grid item xs={12} md={'auto'}>
                        <SelectInput
                            sx={{marginTop: 0}}
                            fullWidth
                            source="sex"
                            label={translate("resources.people.fields.sex")}
                            choices={sexChoices}
                            optionText="label"
                            optionValue="value"
                        />
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <DateInput label={translate("resources.people.fields.birthday")} source="birthday"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">{translate('resources.people.create.title2')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <ReferenceInput source="family.id" reference="Family">
                            <AutocompleteInput
                                label={translate("resources.people.fields.family")}
                                filterToQuery={searchText => ({label: {contains: searchText, mode: 'Insensitive'}})}
                                createValue={translate('resources.people.create.createNewFamily')}
                                create={<CreateFamilyDialog/>}
                                sx={{marginTop: 0}}
                                size={'small'}
                                fullWidth
                                optionText={FamilyTitle}/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <SelectInput
                            fullWidth
                            source="familyRole"
                            label={translate("resources.people.fields.familyRole")}
                            choices={familyRolesChoices}
                            optionText="label"
                            optionValue="value"
                            sx={{marginTop: 0}}
                        />
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <BooleanInput label={translate('resources.people.fields.isAdopted')} source="isAdopted"/>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <BooleanInput label={translate("resources.people.fields.liveSeparately")} source="liveSeparately"/>
                    </Grid>
                    <Grid item xs={12} mb={4}><PersonContacts isMobile={isXsmall}/></Grid>
                    <Grid item xs={12} mb={4}><PersonAddresses isMobile={isXsmall}/></Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">{translate('resources.people.create.title4')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <BasicTabs isMobile={isXsmall}>
                            <Docs personId={recordId} isMobile={isXsmall}/>
                            <Photos/>
                        </BasicTabs>
                    </Grid>
                    <Box width="100%"/>
                    <Grid item xs={12} md={'auto'}><Actions/></Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};
