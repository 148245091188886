import {TranslationMessages} from 'react-admin';
import uaMessages from 'ra-language-ukrainian';
import {MAX_FILE_SIZE} from "../contsatns/common";

const customFrenchMessages: TranslationMessages = {
    ...uaMessages,
    ra: {
        ...uaMessages.ra,
        action: {
            ...uaMessages.ra.action,
            clear_array_input: 'Очистити',
            btn_search: 'Шукати',
        },
        message: {
            ...uaMessages.ra.message,
            clear_array_input: 'Очистити',
            delete_conf_title: 'Видаллення',
        },
        notification: {
            ...uaMessages.ra.notification,
            edited: 'Елемент оновлено',
            file_uploaded: 'Файл завантажено',
            'file-invalid-type': 'Невірний тип файлу',
            'file-too-large': `Розмір файлу не повинен перевищувати ${MAX_FILE_SIZE/1000} КБ`,
        }
    },
    pos: {
        search: 'Пошук',
        configuration: 'Налаштування',
        language: 'Мова',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            monthly_revenue: 'CA à 30 jours',
            month_history: "Chiffre d'affaire sur 30 jours",
            new_orders: 'Nouvelles commandes',
            pending_reviews: 'Commentaires à modérer',
            all_reviews: 'Voir tous les commentaires',
            new_customers: 'Nouveaux clients',
            all_customers: 'Voir tous les clients',
            pending_orders: 'Commandes à traiter',
            order: {
                items:
                    'par %{customer_name}, un poster |||| par %{customer_name}, %{nb_items} posters',
            },
            welcome: {
                title: 'Bienvenue sur la démo e-commerce de react-admin',
                subtitle:
                    "Ceci est le back-office d'un magasin de posters imaginaire. N'hésitez pas à explorer et à modifier les données. La démo s'exécute en local dans votre navigateur, et se remet à zéro chaque fois que vous rechargez la page.",
                ra_button: 'Site web de react-admin',
                demo_button: 'Code source de cette démo',
            },
        },
        menu: {
            sales: 'Ventes',
            catalog: 'Catalogue',
            customers: 'Clients',
        },
    },
    layout: {
        appBar: {
            configuration: 'Налаштування'
        },
        menu: {
            location: 'Локації'
        }
    },
    pages: {
        dashboard: {
            welcome: 'Ласкаво просимо до адмін панелі Слобода UA',
            welcome1: 'Ви можете ',
            link1: 'додати особу',
            welcome2: ' або переглянути ',
            link2: 'весь список осіб.',
        }
    },
    resources: {
        users: {
            name: 'Користувач |||| Користувачі',
            fields: {
                firstName: 'Ім\'я',
                lastName: 'Прізвище',
                fullName: 'Ім\'я та прізвище',
                password: 'Пароль',
                roles: 'Ролі',
                createdAt: 'Створено',
                updatedAt: 'Оновлено',
                deletedAt: 'Видалено',
                id: 'ID',
                username: 'Ім\'я користувача',
            },
            filters: {
                search: 'Пошук',
            },
            fieldGroups: {},
            section: {
                list: 'Користувачі',
                edit: 'Користувач %{name}',
                show: 'Користувач %{name}',
                create: 'Створити користувача',
            },
            errors: {},
        },
        countries: {
            name: 'Країна |||| Країни',
            fields: {
                label: 'Назва',
                name: 'Назва',
                createdAt: 'Створено',
                updatedAt: 'Оновлено',
                personAddresses: 'Адреси',
                slug: 'Slug',
                state: 'Області',
                id: 'ID',
                appartment: 'Квартира',
                city: 'Місто',
                common: 'ОТГ',
                country: 'Країна',
                district: 'Район',
                endDate: 'Закінчення',
                house: 'Буд.',
                person: 'Особа',
                startDate: 'Початок',
                street: 'Вул.',
            },
        },
        states: {
            name: 'Область |||| Області',
            fields: {
                label: 'Назва',
                createdAt: 'Створено',
                updatedAt: 'Оновлено',
                common: 'ОТГ',
                slug: 'Slug',
                country: 'Країна',
                id: 'ID',
                personAddresses: 'Адреси',
                state: 'Області',
                appartment: 'Квартира',
                city: 'Місто',
                district: 'Район',
                endDate: 'Закінчення',
                startDate: 'Початок',
                street: 'Вул.',
                person: 'Особа',
                house: 'Буд.',
            }
        },
        commons: {
            name: 'Громада |||| Громади',
            fields: {
                label: 'Назва',
                createdAt: 'Створено',
                updatedAt: 'Оновлено',
                slug: 'Slug',
                cities: 'Міста',
                state: 'Область',
                id: 'ID',
                personAddresses: 'Адреси',
                city: 'Місто',
                common: 'ОТГ',
                appartment: 'Квартира',
                district: 'Район',
                endDate: 'Закінчення',
                startDate: 'Початок',
                street: 'Вул.',
                person: 'Особа',
                house: 'Буд.',
                country: 'Країна',
            }
        },
        appartment:{
            name: 'квартира |||| квартира',
            fields: {
                appartments: 'Квартира',
            }
        },
        cities: {
            name: 'Місто |||| Міста',
            fields: {
                common: 'Громада',
                createdAt: 'Створено',
                districts: 'Райони',
                id: 'ID',
                label: 'Назва',
                slug: 'Slug',
                streets: 'Вулиці',
                updatedAt: 'Оновлено',
                personAddresses: 'Адреси',
                Email: 'Електронна пошта',
                street: 'Вул.',
            }
        },
        districts: {
            name: 'Район |||| Райони',
            fields: {
                city: 'Місто',
                createdAt: 'Створено',
                id: 'ID',
                label: 'Назва',
                slug: 'Slug',
                streets: 'Вулиці',
                updatedAt: 'Оновлено',
                Email: 'Електронна пошта',
                personAddresses: 'Адреси',
                appartment: 'Квартира',
                district: 'Район',
                endDate: 'Закінчення',
                startDate: 'Початок',
                street: 'Вул.',
                person: 'Особа',
                house: 'Буд.',
                country: 'Країна',
                common: 'ОТГ',
                state: 'Області',
            }
        },
        streets: {
            name: 'Вулиця |||| Вулиці',
            fields: {
                city: 'Місто',
                createdAt: 'Створено',
                district: 'Район',
                id: 'ID',
                label: 'Назва',
                slug: 'Slug',
                updatedAt: 'Оновлено',
                Email: 'Електронна пошта',
                appartment: 'Квартира',
                endDate: 'Закінчення',
                startDate: 'Початок',
                street: 'Вул.',
                person: 'Особа',
                house: 'Буд.',
                country: 'Країна',
                common: 'ОТГ',
                state: 'Області',
                personAddresses: 'Адреси',
            }
        },
        files: {
            name: 'Файл |||| Файли',
            fields: {
                createdAt: 'Створено',
                id: 'ID',
                name: 'Назва',
                updatedAt: 'Оновлено',
                url: 'URL',
                info: 'Інформація',
                path: 'Шлях',
                person: 'Особа',
                action: 'Дія',
                doc: 'Документ',
                docSerial: 'Номер документа',
            }
        },
        families: {
            name: 'Сім\'я |||| Сім\'ї',
            fields: {
                createdAt: 'Створено',
                id: 'ID',
                name: 'Назва',
                updatedAt: 'Оновлено',
                person: 'Особи',
                label: 'Прізвище',
                status: 'Статус',
                familyStatus: 'Статус сім\'ї',
                createdAtfamilies: 'Створено',
                labelfamilies: 'Назва',
                birthday: 'День народження',
                family: 'Сім\'я',
                familyRole: 'Роль в сім\'ї',
                firstName: 'Ім\'я',
                isAdopted: 'Прийомна дитина',
                liveSeparately: 'Живе окремо',
                lastName: 'Прізвище',
                sex: 'Стать',
                surname: 'По батькові',
                
            },
            data: {
                father: 'Батько',
                mother: 'Мати',
                daughter: 'Дочка',
                son: 'Син',
                grandfather: 'Дідусь',
                grandmother: 'Бабуся',
                male: 'Чоловік',
                female: 'Жінка',
            },
        },
        familyStatuses: {
            name: 'Статус сім\'ї |||| Статуси сім\'ї',
            fields: {
                createdAt: 'Створено',
                id: 'ID',
                name: 'Назва',
                updatedAt: 'Оновлено',
                labelStatuses: 'Назва',
                updatedAtStatuses: 'Оновлено',
                family: 'Сім\'я',
                label: 'Назва',
                familyStatus: 'Статус сім\'ї',
            }
        },
        people: {
            name: 'Особа |||| Особи',
            fields: {
                actions: 'Дії',
                birthday: 'День народження',
                contacts: 'Контакти',
                createdAt: 'Створено',
                docs: 'Документи',
                family: 'Сім\'я',
                familyRole: 'Роль в сім\'ї',
                firstName: 'Ім\'я',
                id: 'ID',
                isAdopted: 'Прийомна дитина',
                lastName: 'Прізвище',
                liveSeparately: 'Живе окремо',
                personAddresses: 'Адреси',
                photos: 'Фото',
                sex: 'Стать',
                surname: 'По батькові',
                updatedAt: 'Оновлено',
                files: 'Файли',
            },
            show: {
                title: 'Особа #%{id}',
                fullName: 'П.І.Б.',
            },
            conjunctions: {
                inFamily: ' в сім\'ї ',
            },
            create: {
                title: 'Створити особу',
                title1: 'Особиста інформація',
                title2: 'Сім\'я',
                title3: 'Контакти',
                title31: 'Адреси',
                title4: 'Інше',
                createNewFamily: 'Створити нову сім\'ю',
                createNewContact: 'Створити новий контакт',
                step1Title: 'Особиста інформація',
                step2Title: 'Контактна інформація',
                step3Title: 'Файли',
                optional: 'Опціонально',
                skip: 'Пропустити',
                next: 'Далі',
                back: 'Назад',
                finish: 'Закінчити',
                successCreated: 'Особу успішно створено',
                successUpdated: 'Особу успішно оновлено',
                errorSkipStep: 'Ви не можете пропустити цей крок',
                allStepsCompleted: 'Ви успішно створили нову особу!',
                result1: 'Ви можете перейти до ',
                result1Btn: 'списку людей',
                result2: ' або створити ще ',
                result2Btn: 'одну особу',
            },
            edit: {
                title: 'Редагувати особу',
                asideTitle: 'Сім\'я',
            },
        },
        docs: {
            name: 'Документ |||| Документи',
            fields: {
                createdAt: 'Створено',
                id: 'ID',
                name: 'Назва',
                updatedAt: 'Оновлено',
                file: 'Файл',
                person: 'Особа',
                path: 'Шлях',
                docType: 'Тип документа',
                docSerial: 'Номер документа',
            },
            create: {
                title: 'Створити документ',
                createNewDoc: 'Створити новий документ',
            },
            edit: {
                title: 'Редагувати документ',
            }
        },
        docTypes: {
            name: 'Тип документа |||| Типи документів',
            fields: {
                createdAt: 'Створено',
                id: 'ID',
                name: 'Назва',
                updatedAt: 'Оновлено',
                label: 'Назва',
                doc: 'Документ',
                docType: 'Тип документа',
                docSerial: 'Номер документа',
                file: 'Файл',
                person: 'Особа',

            }
        },
        contacts: {
            name: 'Контакт |||| Контакти',
            fields: {
                createdAt: 'Створено',
                id: 'ID',
                name: 'Назва',
                updatedAt: 'Оновлено',
                value: 'Значення',
                contactType: 'Тип',
                person: 'Особа',
                Phone: 'Телефон',
                Email: 'Електронна пошта',
                
            },
            createDialog: {
                title: 'Створити контакт',
                save: 'Зберегти',
                cancel: 'Відмінити',
            },
            action: {
                create: 'Створити контакт',
                save: 'Зберегти',
                cancel: 'Відмінити',
                edit: 'Редагувати',
            },
            data: {
                phone: 'Телефон',
                email: 'Електронна пошта',
            }
        },
        personAddresses: {
            name: 'Адреса особи |||| Адреси особи',
            fields: {
                createdAt: 'Створено',
                id: 'ID',
                name: 'Назва',
                updatedAt: 'Оновлено',
                startDate: 'Початок',
                apartment: 'Кв.',
                city: 'Місто',
                country: 'Країна',
                district: 'Район',
                house: 'Буд.',
                state: 'Область',
                common: 'ОТГ',
                street: 'Вул.',
                endDate: 'Закінчення',
                person: 'Особа',
            },
            action: {
                create: 'Створити адресу',
                save: 'Зберегти',
                cancel: 'Відмінити',
                edit: 'Редагувати',
            },
        },
        actions: {
            name: 'Дія |||| Дії',
            fields: {
                createdAt: 'Створено',
                id: 'ID',
                name: 'Назва',
                updatedAt: 'Оновлено',
                actionType: 'Тип дії',
                person: 'Особа',
                date: 'Дата',
                description: 'Опис',
                photos: 'Фото',
                user: 'Користувач',
            },
            create: {
                title: 'Створити дію',
                createNewAction: 'Створити нову дію',
            }
        },
        actionTypes: {
            name: 'Тип дії |||| Типи дій',
            fields: {
                createdAt: 'Створено',
                id: 'ID',
                name: 'Назва',
                updatedAt: 'Оновлено',
                actions: 'Дії',
                label: 'Назва',
                actionType: 'Тип дії',
                date: 'Дата',
                description: 'Опис',
                person: 'Особа',
                user: 'Користувач',
            }
        },
        page: {
            create: "Створити %{name}",
            dashboard: "Дашборд",
            edit: "%{name} #%{id}",
            error: "Щось пішло не так",
            list: "%{name}",
            loading: "Завантаження",
            not_found: "Не знайдено",
            show: "%{name}3434 #%{id}",
            empty: "Ще немає %{name}.",
            invite: "Бажаєте додати?",
        },
    }
};

export default customFrenchMessages;
