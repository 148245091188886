import {useCallback, useState} from "react";
import {useDelete} from "react-admin";

type UseEditDialogResources = 'Contact' | 'PersonAddress' | 'Doc' | 'File';

export const useDeleteConfirm = (resource: UseEditDialogResources) => {
    const [isOpenDeleteDialog, setDeleteOpen] = useState(false);
    const [deletedId, setDeletedId] = useState<string | null>(null);

    const confirmDelete = useCallback(() => {
        deleteOne(resource, {id: deletedId})
        closeDeleteDialog();
    }, [deletedId]);
    const openDeleteDialog = useCallback(() => {
        setDeleteOpen(true);
    }, []);
    const closeDeleteDialog = useCallback(() => {
        setDeleteOpen(false);
    }, []);
    const [deleteOne] = useDelete();
    const deleteEntity = (id: string) => {
        setDeletedId(id);
        openDeleteDialog();
    };

    return {
        isOpenDeleteDialog,
        closeDeleteDialog,
        confirmDelete,
        openDeleteDialog,
        deleteEntity,
    };
}
