import * as yup from "yup";
import {Street} from "../api/street/Street";
import {yupResolver} from "@hookform/resolvers/yup";

export const createSchema = yupResolver(yup
    .object<Street>()
    .shape({
        label: yup.string().required(),
        slug: yup.string().required(),
        city: yup.object({
            id: yup.string().required(),
        }),
        // district: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
        // personAddresses: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
    })
    .required()
);
