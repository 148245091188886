import {DatagridBody, DatagridBodyProps, RecordContextProvider, useTranslate} from "react-admin";
import * as React from "react";
import {useCallback} from "react";
import {IconButton, TableCell, TableRow} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const MyDataGridRow = ({record, id, onEditClick, onDeleteClick, children}: any) => {
    const translate = useTranslate();
    const handleEditClick = useCallback(() => {
        onEditClick(id);
    }, [id]);
    const handleDeleteClick = useCallback(() => {
        onDeleteClick(id);
    }, [id]);
    return (
        <RecordContextProvider value={record}>
            <TableRow>
                <TableCell padding="none">
                    <IconButton
                        title={translate('resources.contacts.action.edit')}
                        color={"primary"}
                        onClick={handleEditClick}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton
                        color={"error"}
                        title={translate('ra.action.delete')}
                        onClick={handleDeleteClick}>
                        <DeleteIcon/>
                    </IconButton>
                </TableCell>
                {/* data columns based on children */}
                {React.Children.map(children, field => (
                    <TableCell key={`${id}-${field.props.source}`}>
                        {field}
                    </TableCell>
                ))}
            </TableRow>
        </RecordContextProvider>
    );
};

export const DataGridBody = ({onEditClick, onDeleteClick, ...props}: DatagridBodyProps & {
    onEditClick: (id: string) => void;
    onDeleteClick: (id: string) => void
}) =>
    <DatagridBody
        {...props}
        row={<MyDataGridRow onEditClick={onEditClick} onDeleteClick={onDeleteClick}/>}
    />;
