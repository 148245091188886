import * as React from "react";

import {
  Edit,
  SimpleForm,
  EditProps,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateInput,
} from "react-admin";

import { CityTitle } from "../city/CityTitle";
import { CommonTitle } from "../common/CommonTitle";
import { CountryTitle } from "../country/CountryTitle";
import { DistrictTitle } from "../district/DistrictTitle";
import { PersonTitle } from "../person/PersonTitle";
import { StateTitle } from "../state/StateTitle";
import { StreetTitle } from "../street/StreetTitle";

export const PersonAddressEdit = (props: EditProps): React.ReactElement => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput label="Appartment" source="appartment" />
        <ReferenceInput source="city.id" reference="City" label="City">
          <SelectInput optionText={CityTitle}  />
        </ReferenceInput>
        <ReferenceInput source="common.id" reference="Common" label="Common">
          <SelectInput optionText={CommonTitle} />
        </ReferenceInput>
        <ReferenceInput source="country.id" reference="Country" label="Country">
          <SelectInput optionText={CountryTitle} />
        </ReferenceInput>
        <ReferenceInput
          source="district.id"
          reference="District"
          label="District"
        >
          <SelectInput optionText={DistrictTitle} />
        </ReferenceInput>
        <DateInput label="End Date" source="endDate" />
        <TextInput label="House" source="house" />
        <ReferenceInput source="person.id" reference="Person" label="Person">
          <SelectInput optionText={PersonTitle} />
        </ReferenceInput>
        <DateInput label="Start Date" source="startDate" />
        <ReferenceInput source="state.id" reference="State" label="State">
          <SelectInput optionText={StateTitle} />
        </ReferenceInput>
        <ReferenceInput source="street.id" reference="Street" label="Street">
          <SelectInput optionText={StreetTitle} />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
