import * as React from "react";

import {
    Create,
    CreateProps,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from "react-admin";

import {CityTitle} from "../city/CityTitle";
import {DistrictTitle} from "../district/DistrictTitle";
import {PersonAddressTitle} from "../personAddress/PersonAddressTitle";
import {createTransformFn} from "../util/createTransformFn";
import {Street} from "../api/street/Street";
import {createSchema} from "./validation";


const transform = createTransformFn<Street>(['district', 'personAddresses']);
export const StreetCreate = (props: CreateProps): React.ReactElement => {
    return (
        <Create {...props} redirect={'list'} transform={transform}>
            <SimpleForm resolver={createSchema}>
                <ReferenceInput source="city.id" reference="City">
                    <SelectInput optionText={CityTitle} label="resources.streets.fields.city"/>
                </ReferenceInput>
                <ReferenceArrayInput
                    source="district"
                    reference="District">
                    <SelectArrayInput
                        label="resources.streets.fields.district"
                        defaultValue={[]}
                        optionText={DistrictTitle}
                    />
                </ReferenceArrayInput>
                <TextInput label="resources.streets.fields.label" source="label"/>
                <ReferenceArrayInput
                    source="personAddresses"
                    queryOptions={{meta: {foo: 'bar'}}}
                    reference="PersonAddress">
                    <SelectArrayInput
                        label="resources.streets.fields.Email"
                        defaultValue={[]}
                        optionText={PersonAddressTitle}
                    />
                </ReferenceArrayInput>
                <TextInput label="Slug" source="slug"/>
            </SimpleForm>
        </Create>
    );
};
