import React from "react";
import {
    Confirm,
    Datagrid,
    Identifier,
    ImageField,
    ReferenceField,
    ReferenceManyField,
    SimpleList,
    TextField,
    useTranslate,
} from "react-admin";
import {DataGridHeader} from "../components/DataGridHeader";
import {DataGridBody} from "../components/DataGridBody";
import {DOCTYPE_TITLE_FIELD} from "../../docType/DocTypeTitle";
import {useEditDialog} from "./useEditDialog";
import {EmptyTitleDataGrid} from "../../Components/EmptyTitleDataGrid";
import {useDeleteConfirm} from "./useDeleteConfirm";
import {DocCreateDialog} from "../components/DocCreateDialog";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import {DocUpdateDialog} from "../components/DocUpdateDialog";
import {ListIconWrapper} from "../components/ListIconWrapper";
import {useListRightIcon} from "./useListRightIcon";


interface PhotosProps {
    personId: Identifier;
    isMobile?: boolean;
}

export const Docs: React.FC<PhotosProps> = ({personId, isMobile}) => {
    const translate = useTranslate();
    const {
        openEditDialog,
        isOpenEditDialog,
        editedId,
        openCreateDialog,
        isOpenCreateDialog,
        closeCreateDialog,
        closeEditDialog
    } = useEditDialog();
    const {
        deleteEntity,
        closeDeleteDialog,
        isOpenDeleteDialog,
        confirmDelete,
    } = useDeleteConfirm('Doc');
    const rightIcon = useListRightIcon('Doc');
    return <>
        <Confirm
            isOpen={isOpenDeleteDialog}
            title={`ra.message.delete_conf_title`}
            content={`ra.message.delete_content`}
            onConfirm={confirmDelete}
            onClose={closeDeleteDialog}
        />
        <Button onClick={openCreateDialog} startIcon={<AddIcon/>} variant={'text'}>
            {translate('resources.docs.create.createNewDoc')}
        </Button>
        <ReferenceManyField
            label="Docs"
            target={'person'}
            reference="Doc">
            {
                isMobile ?
                    <ListIconWrapper>
                        <SimpleList
                            primaryText="%{docSerial}"
                            secondaryText={
                                <ReferenceField
                                    label={translate("resources.docs.fields.docType")}
                                    source="docType.id"
                                    reference="DocType"
                                    link={false}>
                                    <TextField
                                        component={'span'}
                                        source={DOCTYPE_TITLE_FIELD}/>
                                </ReferenceField>
                            }
                            linkType={false}
                            rightIcon={rightIcon}>
                            <ReferenceField
                                label={translate('resources.docs.fields.file')}
                                source="file.id"
                                link={false}
                                reference="File">
                                <ImageField
                                    source="path"
                                    title={translate("resources.docs.fields.file")}/>
                            </ReferenceField>
                        </SimpleList>
                    </ListIconWrapper> :
                    <Datagrid
                        header={<DataGridHeader/>}
                        body={<DataGridBody onEditClick={openEditDialog} onDeleteClick={deleteEntity}/>}
                        empty={<EmptyTitleDataGrid/>}>
                        <ReferenceField
                            label={translate('resources.docs.fields.file')}
                            source="file.id"
                            link={false}
                            reference="File">
                            <ImageField
                                source="path"
                                title={translate("resources.docs.fields.file")}/>
                        </ReferenceField>
                        <ReferenceField
                            label={translate("resources.docs.fields.docType")}
                            source="docType.id"
                            reference="DocType"
                            link={false}>
                            <TextField source={DOCTYPE_TITLE_FIELD}/>
                        </ReferenceField>
                        <TextField
                            label={translate("resources.docs.fields.docSerial")}
                            source="docSerial"
                        />
                    </Datagrid>
            }
        </ReferenceManyField>
        <DocCreateDialog
            isOpen={isOpenCreateDialog}
            onClose={closeCreateDialog}
            personId={personId}
        />
        {
            editedId &&
            <DocUpdateDialog
                isOpen={isOpenEditDialog}
                onClose={closeEditDialog}
                id={editedId}
            />
        }
    </>
}