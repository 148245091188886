import {TableCell, TableHead, TableRow} from "@mui/material";
import {Children} from "react";
import * as React from "react";

export const DataGridHeader: React.FC = ({children}: any) => (
    <TableHead>
        <TableRow>
            <TableCell padding="none"></TableCell>
            {Children.map(children, child => (
                <TableCell key={child.props.source}>
                    {child.props.label}
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
);