import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    ShowProps,
    DateField,
    TextField, FunctionField, useTranslate,
} from "react-admin";
import {renderRolesField} from "./RolesField";

export const UserShow = (props: ShowProps): React.ReactElement => {
    const translate = useTranslate();
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <DateField label={translate('resources.users.fields.createdAt')} source="createdAt"/>
                <TextField label={translate('resources.users.fields.firstName')} source="firstName"/>
                <TextField label={translate('resources.users.fields.id')} source="id"/>
                <TextField label={translate('resources.users.fields.lastName')} source="lastName"/>
                <FunctionField label={translate('resources.users.fields.roles')} source="roles"
                               render={renderRolesField}/>
                <DateField source="updatedAt" label={translate('resources.users.fields.updatedAt')}/>
                <TextField label={translate('resources.users.fields.username')} source="username"/>
            </SimpleShowLayout>
        </Show>
    );
};
