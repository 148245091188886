import * as React from "react";

import {
    BooleanField,
    Datagrid,
    DateField,
    FunctionField,
    ReferenceField,
    ReferenceManyField,
    SelectField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
    useTranslate,
} from "react-admin";

import {ACTIONTYPE_TITLE_FIELD} from "../actionType/ActionTypeTitle";
import {PERSON_TITLE_FIELD} from "./PersonTitle";
import {USER_TITLE_FIELD} from "../user/UserTitle";
import {DOCTYPE_TITLE_FIELD} from "../docType/DocTypeTitle";
import {FILE_TITLE_FIELD} from "../file/FileTitle";
import {CITY_TITLE_FIELD} from "../city/CityTitle";
import {COMMON_TITLE_FIELD} from "../common/CommonTitle";
import {COUNTRY_TITLE_FIELD} from "../country/CountryTitle";
import {DISTRICT_TITLE_FIELD} from "../district/DistrictTitle";
import {STATE_TITLE_FIELD} from "../state/StateTitle";
import {STREET_TITLE_FIELD} from "../street/StreetTitle";
import {FAMILY_TITLE_FIELD} from "../family/FamilyTitle";
import {Person} from "../api/person/Person";
import {familyRolesChoices} from "../contsatns/common";
import {Container, Divider} from "@mui/material";

export const PersonShow = (props: ShowProps): React.ReactElement => {
    const translate = useTranslate();
    return (
        <Container>
            <Show{...props}>
                <SimpleShowLayout>
                    <FunctionField
                        label="resources.people.show.fullName"
                        render={(record: Person) => `${record.lastName} ${record.firstName} ${record.surname || ''}`}
                    />
                    <FunctionField
                        label="resources.people.fields.familyRole"
                        render={() => <>
                            <SelectField
                                label="resources.people.fields.familyRole"
                                source="familyRole"
                                choices={familyRolesChoices}
                                optionText="label"
                                optionValue="value"
                            />
                            {translate('resources.people.conjunctions.inFamily')}
                            <ReferenceField
                                label="resources.people.fields.family"
                                source="family.id"
                                reference="Family">
                                <TextField source={FAMILY_TITLE_FIELD}/>
                            </ReferenceField>

                        </>}
                    />
                    <DateField label="resources.people.fields.birthday" source="birthday"/>
                    {/*<TextField label="resources.people.fields.id" source="id"/>*/}
                    <BooleanField label="resources.people.fields.isAdopted" source="isAdopted"/>
                    <BooleanField label="resources.people.fields.liveSeparately" source="liveSeparately"/>
                    <TextField label="resources.people.fields.sex" source="sex" emptyText={'-'}/>
                    <DateField source="createdAt" label="resources.people.fields.createdAt"/>
                    <DateField source="updatedAt" label="resources.people.fields.updatedAt"/>

                    <Divider sx={{mb: 2}}/>
                    <ReferenceManyField
                        reference="Action"
                        target="PersonId"
                        label="resources.people.fields.actions"
                    >
                        <Datagrid rowClick="show" sx={{mb: 2}}>
                            <ReferenceField
                                label="Action Type"
                                source="actiontype.id"
                                reference="ActionType"
                            >
                                <TextField source={ACTIONTYPE_TITLE_FIELD}/>
                            </ReferenceField>
                            <DateField source="createdAt" label="Created At"/>
                            <TextField label="Date" source="date"/>
                            <TextField label="Description" source="description"/>
                            <TextField label="ID" source="id"/>
                            <ReferenceField
                                label="Person"
                                source="person.id"
                                reference="Person"
                            >
                                <TextField source={PERSON_TITLE_FIELD}/>
                            </ReferenceField>
                            <DateField source="updatedAt" label="Updated At"/>
                            <ReferenceField label="User" source="user.id" reference="User">
                                <TextField source={USER_TITLE_FIELD}/>
                            </ReferenceField>
                        </Datagrid>
                    </ReferenceManyField>

                    <ReferenceManyField
                        reference="Contact"
                        target="PersonId"
                        label="resources.people.fields.contacts"
                    >
                        <Datagrid rowClick="show" sx={{mb: 2}}>
                            <TextField label="resources.contacts.fields.contactType" source="contactType"/>
                            <TextField label="resources.contacts.fields.value" source="value"/>
                            <DateField source="createdAt" label="resources.contacts.fields.createdAt"/>
                        </Datagrid>
                    </ReferenceManyField>

                    <ReferenceManyField
                        reference="Doc"
                        target="PersonId"
                        label="resources.people.fields.docs"
                    >
                        <Datagrid rowClick="show" sx={{mb: 2}}>
                            <DateField source="createdAt" label="resources.docs.fields.createdAt"/>
                            <TextField label="resources.docs.fields.docSerial" source="docSerial"/>
                            <ReferenceField
                                label="resources.docs.fields.docType"
                                source="doctype.id"
                                reference="DocType"
                            >
                                <TextField source={DOCTYPE_TITLE_FIELD}/>
                            </ReferenceField>
                            <ReferenceField label="resources.docs.fields.file" source="file.id" reference="File">
                                <TextField source={FILE_TITLE_FIELD}/>
                            </ReferenceField>
                        </Datagrid>
                    </ReferenceManyField>

                    <ReferenceManyField
                        reference="PersonAddress"
                        target="PersonId"
                        label="resources.people.fields.personAddresses"
                    >
                        <Datagrid rowClick="show" sx={{mb: 2}}>
                            <ReferenceField
                                label="resources.personAddresses.fields.country"
                                source="country.id"
                                reference="Country"
                            >
                                <TextField source={COUNTRY_TITLE_FIELD}/>
                            </ReferenceField>
                            <ReferenceField
                                label="resources.personAddresses.fields.state"
                                source="state.id"
                                reference="State">
                                <TextField source={STATE_TITLE_FIELD}/>
                            </ReferenceField>

                            <ReferenceField
                                label="resources.personAddresses.fields.common"
                                source="common.id"
                                reference="Common"
                            >
                                <TextField source={COMMON_TITLE_FIELD}/>
                            </ReferenceField>
                            <ReferenceField
                                label="resources.personAddresses.fields.city"
                                source="city.id"
                                reference="City">
                                <TextField source={CITY_TITLE_FIELD}/>
                            </ReferenceField>
                            <ReferenceField
                                label="resources.personAddresses.fields.district"
                                source="district.id"
                                reference="District"
                            >
                                <TextField source={DISTRICT_TITLE_FIELD}/>
                            </ReferenceField>
                            <ReferenceField
                                label="resources.personAddresses.fields.street"
                                source="street.id"
                                reference="Street"
                            >
                                <TextField source={STREET_TITLE_FIELD}/>
                            </ReferenceField>
                            <TextField label="resources.personAddresses.fields.apartment" source="house"/>
                            <TextField label="resources.personAddresses.fields.apartment" source="appartment"/>
                        </Datagrid>
                    </ReferenceManyField>

                    <ReferenceManyField
                        reference="File"
                        target="PersonId"
                        label="resources.people.fields.files"
                    >
                        <Datagrid rowClick="show" sx={{mb: 2}}>
                            <DateField source="createdAt" label="resources.files.fields.createdAt"/>
                            <TextField label="resources.files.fields.iD" source="id"/>
                            <TextField label="resources.files.fields.info" source="info"/>
                            <TextField label="resources.files.fields.path" source="path"/>
                            <ReferenceField
                                label="resources.files.fields.person"
                                source="person.id"
                                reference="Person"
                            >
                                <TextField source={PERSON_TITLE_FIELD}/>
                            </ReferenceField>
                            <DateField source="updatedAt" label="resources.files.fields.updatedAt"/>
                        </Datagrid>
                    </ReferenceManyField>
                </SimpleShowLayout>
            </Show>
        </Container>
    );
};
