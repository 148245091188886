import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {FamilyStatus} from "../api/familyStatus/FamilyStatus";

export const createSchema = yupResolver(yup
    .object<FamilyStatus>()
    .shape({
        label: yup.string().required(),
        slug: yup.string().required(),
        // families: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
    })
    .required()
);
