import {gql} from "@apollo/client/core";
import {apolloClient} from "./graphqlDataProvider";

export const personAutoCompleteQuery = gql`
    query personAutoComplete($limit: Float, $query: String) {
        items: personAutoComplete(limit: $limit, query: $query) {
            actions {
                id
                __typename
            }
            birthday
            contacts {
                id
                __typename
            }
            createdAt
            docs {
                id
                __typename
            }
            family {
                id
                __typename
            }
            familyRole
            firstName
            id
            isAdopted
            lastName
            liveSeparately
            personAddresses {
                id
                __typename
            }
            photos {
                id
                __typename
            }
            sex
            surname
            updatedAt
            __typename
        }
    }
`;

export function query(value: string, limit= 100) {
    return apolloClient.query({
        query: personAutoCompleteQuery,
        variables: {
            limit,
            query: value
        },
    });
}