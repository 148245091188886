import * as React from "react";

import {
  Create,
  SimpleForm,
  CreateProps,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";

import { CommonTitle } from "../common/CommonTitle";
import { CountryTitle } from "../country/CountryTitle";
import { PersonAddressTitle } from "../personAddress/PersonAddressTitle";
import {createSchema} from "./validation";
import {State} from "../api/state/State";
import {createTransformFn} from "../util/createTransformFn";

const transform = createTransformFn<State>(['commons', 'personAddresses']);
export const StateCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props} transform={transform} redirect={'list'}>
      <SimpleForm resolver={createSchema}>
        <ReferenceArrayInput
          source="commons"
          reference="Common"
        >
          <SelectArrayInput optionText={CommonTitle} label="resources.states.fields.common"  />
        </ReferenceArrayInput>
        <ReferenceInput source="country.id" reference="Country" >
          <SelectInput optionText={CountryTitle} label="resources.states.fields.country" />
        </ReferenceInput>
        <TextInput label="resources.states.fields.label" source="label" />
        <ReferenceArrayInput
          source="personAddresses"
          reference="PersonAddress"
        >
          <SelectArrayInput optionText={PersonAddressTitle} label="resources.states.fields.personAddresses" />
        </ReferenceArrayInput>
        <TextInput label="Slug" source="slug" />
      </SimpleForm>
    </Create>
  );
};
