import * as React from "react";
import {
  List,
  Datagrid,
  ListProps,
  TextField,
  ReferenceField,
  DateField,
} from "react-admin";
import Pagination from "../Components/Pagination";
import { CITY_TITLE_FIELD } from "../city/CityTitle";
import { COMMON_TITLE_FIELD } from "../common/CommonTitle";
import { COUNTRY_TITLE_FIELD } from "../country/CountryTitle";
import { DISTRICT_TITLE_FIELD } from "../district/DistrictTitle";
import { PERSON_TITLE_FIELD } from "../person/PersonTitle";
import { STATE_TITLE_FIELD } from "../state/StateTitle";
import { STREET_TITLE_FIELD } from "../street/StreetTitle";

export const PersonAddressList = (props: ListProps): React.ReactElement => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Person Addresses"}
      perPage={50}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show">
        <TextField label="resources.appartment.fields.appartments" source="appartment" />
        <ReferenceField label="resources.districts.fields.city" source="city.id" reference="City">
          <TextField source={CITY_TITLE_FIELD}  />
        </ReferenceField>
        <ReferenceField label="resources.cities.fields.common" source="common.id" reference="Common">
          <TextField source={COMMON_TITLE_FIELD} />
        </ReferenceField>
        <ReferenceField label="resources.states.fields.country" source="country.id" reference="Country">
          <TextField source={COUNTRY_TITLE_FIELD} />
        </ReferenceField>
        <DateField label="resources.users.fields.createdAt" source="createdAt"  />
        <ReferenceField
          label="resources.streets.fields.district"
          source="district.id"
          reference="District"
        >
          <TextField source={DISTRICT_TITLE_FIELD} />
        </ReferenceField>
        <TextField label="resources.personAddresses.fields.endDate" source="endDate" />
        <TextField label="resources.personAddresses.fields.house" source="house" />
        <TextField label="resources.users.fields.id" source="id" />
        <ReferenceField label="resources.files.fields.person" source="person.id" reference="Person">
          <TextField source={PERSON_TITLE_FIELD} />
        </ReferenceField>
        <TextField label="resources.personAddresses.fields.startDate" source="startDate" />
        <ReferenceField label="resources.commons.fields.state" source="state.id" reference="State">
          <TextField source={STATE_TITLE_FIELD} />
        </ReferenceField>
        <ReferenceField label="resources.personAddresses.fields.street" source="street.id" reference="Street">
          <TextField source={STREET_TITLE_FIELD} />
        </ReferenceField>
        <DateField source="updatedAt" label="resources.personAddresses.fields.updatedAt" />
      </Datagrid>
    </List>
  );
};
