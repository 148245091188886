import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import {CommonList} from "./CommonList";
import {CommonCreate} from "./CommonCreate";
import {CommonEdit} from "./CommonEdit";
import {CommonShow} from "./CommonShow";

export default {
    name: 'Common',
    icon: LocalLibraryIcon,
    list: CommonList,
    create: CommonCreate,
    edit: CommonEdit,
    show: CommonShow,
    options: {
        label: 'resources.commons.name'
    },
};
