import * as React from "react";

import {
  Show,
  SimpleShowLayout,
  ShowProps,
  DateField,
  TextField,
  ReferenceField,
  ReferenceManyField,
  Datagrid, ImageField, EditButton, TopToolbar, DeleteButton,
} from "react-admin";

import { DOCTYPE_TITLE_FIELD } from "../docType/DocTypeTitle";
import { FILE_TITLE_FIELD } from "./FileTitle";
import { PERSON_TITLE_FIELD } from "../person/PersonTitle";
import Button from "@mui/material/Button";
import {JsonField} from "../Components/JsonField";

const PostShowActions = () => (
    <TopToolbar>
      <DeleteButton/>
    </TopToolbar>
);



export const FileShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props} actions={<PostShowActions />}>
      <SimpleShowLayout>
        <DateField source="createdAt" label="resources.files.fields.createdAt" />
        <TextField label="ID" source="id" />
        <JsonField label={'resources.files.fields.info'} source={'info'}/>
        <TextField label="resources.files.fields.path" source="path" />
        <ImageField label="resources.files.fields.path" source="path" />
        <ReferenceField label="resources.files.fields.person" source="person.id" reference="Person">
          <TextField source={PERSON_TITLE_FIELD} />
        </ReferenceField>
        <DateField source="updatedAt" label="resources.files.fields.updatedAt" />
        <ReferenceManyField reference="Doc" target="FileId" label="resources.files.fields.doc">
          <Datagrid rowClick="show">
            <DateField source="createdAt" label="resources.files.fields.createdAt" />
            <TextField label="resources.files.fields.docSerial" source="docSerial" />
            <ReferenceField
              label="Doc Type"
              source="doctype.id"
              reference="DocType"
            >
              <TextField source={DOCTYPE_TITLE_FIELD} />
            </ReferenceField>
            <ReferenceField label="File" source="file.id" reference="File">
              <TextField source={FILE_TITLE_FIELD} />
            </ReferenceField>
            <TextField label="ID" source="id" />
            <ReferenceField
              label="Person"
              source="person.id"
              reference="Person"
            >
              <TextField source={PERSON_TITLE_FIELD} />
            </ReferenceField>
            <DateField source="updatedAt" label="Updated At" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
