import * as React from "react";
import {
  List,
  Datagrid,
  ListProps,
  DateField,
  TextField,
  ReferenceField,
} from "react-admin";
import Pagination from "../Components/Pagination";
import { FAMILYSTATUS_TITLE_FIELD } from "../familyStatus/FamilyStatusTitle";

export const FamilyList = (props: ListProps): React.ReactElement => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Families"}
      perPage={50}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show">
        <DateField source="createdAt" label="resources.families.fields.createdAtfamilies" />
        <TextField label="ID" source="id" />
        <TextField label="resources.families.fields.labelfamilies" source="label" />
        <ReferenceField
          label="resources.families.fields.status"
          source="status.id"
          reference="FamilyStatus"
        >
          <TextField source={FAMILYSTATUS_TITLE_FIELD} />
        </ReferenceField>
        <DateField source="updatedAt" label="resources.families.fields.updatedAt" />
      </Datagrid>
    </List>
  );
};
