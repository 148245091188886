import * as React from "react";
import {SimpleForm, Title, useRedirect,} from "react-admin";
import {createDocSchema} from "./validation";
import {DocCreateFields} from "./DocCreateFields";
import {useCreateDoc} from "./useCreateDoc";

export const DocCreate = (): React.ReactElement => {
    const redirect = useRedirect();
    const {handleSubmit} = useCreateDoc({
        onSuccess: () => redirect('/doc')
    })
    return (
        <>
            <Title title="resources.docs.create.title"/>
            <SimpleForm
                onSubmit={handleSubmit}
                resolver={createDocSchema}>
                <DocCreateFields/>
            </SimpleForm>
        </>
    );
};
