import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

export const createDocSchema = yupResolver(yup.object().shape({
    _path: yup.mixed().required(),
    person: yup.object().shape({
        id: yup.string().required(),
    }),
    docSerial: yup.string().required(),
    docType: yup.object().shape({
        id: yup.string().required(),
    }),
}));

export const updateDocSchema = yupResolver(yup.object().shape({
    _path: yup.mixed().optional(),
    // file: yup.object().required(), // todo: this field is required if _path is not set
    person: yup.object().shape({
        id: yup.string().required(),
    }),
    docSerial: yup.string().required(),
    docType: yup.object().shape({
        id: yup.string().required(),
    }),
}));
