import {FunctionField} from "react-admin";

interface Props {
    source: string;
    label?: string;
}

export const JsonField = (props: Props) => {
    return (
        <FunctionField
            label={props.label}
            render={(record: any) => (<pre>{JSON.stringify(record[props.source], null, 2)}</pre>)}/>
    )
}