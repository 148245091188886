import * as React from "react";
import {useCallback, useEffect, useMemo} from "react";

import {
    BooleanField,
    CreateButton,
    Datagrid,
    DateField, Identifier,
    ListContextProvider, RaRecord,
    ReferenceField,
    SelectField,
    TextField, useCreatePath,
    useList,
    useRedirect,
} from "react-admin";
import '../i18n/yupLocale';
import {FAMILY_TITLE_FIELD} from "../family/FamilyTitle";
import {Card} from "@mui/material";
import {familyRolesChoices, sexChoices} from "../contsatns/common";
import Box from "@mui/material/Box/Box";
import {ListFilter} from "./ListFilter";

import {query} from "../data-provider/filterQuery";
import {useLocation} from "react-router-dom";
import {Person} from "../api/person/Person";

export const HomeList = (): React.ReactElement => {
    const [data, setData] = React.useState([]);
    const redirect = useRedirect();
    const location = useLocation();
    const createPath = useCreatePath();
    const handleRowClick = useCallback((id) => createPath({resource: "Person", type: "edit", id}), [createPath]);
    const searchQuery = useMemo(() => new URLSearchParams(location.search).get('q') || '', [location.search]);
    const handleQuery = async (q: string) => {
        const response = await query(q)
        redirect(!q ? '/' : '/?q=' + q);
        setData(response.data.items);
    }
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        handleQuery(searchQuery)
    }, []);
    const listContext = useList<RaRecord>({data});

    return (
        <ListContextProvider value={listContext}>
            <Box sx={{mt: 3, mb: 2, mx: 0.5, justifyContent: 'space-between', display: 'flex'}}>
                <ListFilter onSubmit={handleQuery} searchQuery={searchQuery}/>
                <CreateButton resource={'Person'}/>
            </Box>
            <Card>
                <Datagrid
                    sx={{height: 'calc(100vh - 154px)'}}
                    bulkActionButtons={false}
                    rowClick={handleRowClick}>
                    <TextField label="resources.people.fields.id" source="id"/>
                    <TextField label="resources.people.fields.lastName" source="lastName"/>
                    <TextField label="resources.people.fields.firstName" source="firstName"/>
                    <TextField label="resources.people.fields.surname" source="surname"/>
                    <SelectField
                        source="familyRole"
                        label="resources.people.fields.familyRole"
                        choices={familyRolesChoices}
                        optionText="label"
                        optionValue="value"
                    />
                    <DateField label="resources.people.fields.birthday" source="birthday"/>
                    <SelectField
                        source="sex"
                        label="resources.people.fields.sex"
                        choices={sexChoices}
                        optionText="label"
                        optionValue="value"
                    />
                    <DateField source="createdAt" label="resources.people.fields.createdAt"/>
                    <ReferenceField label="resources.people.fields.family" source="family.id" reference="Family">
                        <TextField source={FAMILY_TITLE_FIELD}/>
                    </ReferenceField>
                    <BooleanField label="resources.people.fields.isAdopted" source="isAdopted"/>
                    <BooleanField label="resources.people.fields.liveSeparately" source="liveSeparately"/>
                    <DateField source="updatedAt" label="resources.people.fields.updatedAt"/>
                </Datagrid>
            </Card>
        </ListContextProvider>


    );
};
