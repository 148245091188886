import * as React from "react";
import {Identifier, ImageField, ImageInput, ReferenceField, ReferenceInput, SelectInput, TextInput,} from "react-admin";
import {DocTypeTitle} from "../docType/DocTypeTitle";
import {PersonTitle} from "../person/PersonTitle";
import {useWatch} from "react-hook-form";
import {useFileSizeValidation} from "../hooks/useFileSizeValidation";

interface Props {
    personId?: Identifier
    isDialog?: boolean
}

export const DocEditFields: React.FC<Props> = ({personId, isDialog}) => {
    const file = useWatch({name: '_path'});
    const fileSizeValidation = useFileSizeValidation();
    return (
        <>
            <ReferenceInput source="person.id" reference="Person">
                <SelectInput
                    optionText={PersonTitle}
                    label="resources.docs.fields.person"
                    disabled={!!personId}
                    fullWidth={isDialog}/>
            </ReferenceInput>
            <TextInput source="docSerial" label="resources.docs.fields.docSerial" fullWidth={isDialog}/>
            <ReferenceInput source="docType.id" reference="DocType">
                <SelectInput optionText={DocTypeTitle} label="resources.docs.fields.docType" fullWidth={isDialog}/>
            </ReferenceInput>
            <ImageInput source="_path" label={'resources.docs.fields.path'} fullWidth={isDialog}
                        {...fileSizeValidation}>
                <ImageField source="src" label={'resources.docs.fields.path'}/>
            </ImageInput>
            {
                !file && <ReferenceField source="file.id" reference="File" label="File">
                    <ImageField source="path" label={'test'}/>
                </ReferenceField>
            }
        </>
    );
};
