import * as React from "react";

import {
  Create,
  SimpleForm,
  CreateProps,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
} from "react-admin";

import { DocTitle } from "../doc/DocTitle";
import {DocType} from "../api/docType/DocType";
import {createTransformFn} from "../util/createTransformFn";
import {createSchema} from "./validation";

const transform = createTransformFn<DocType>(['docs']);
export const DocTypeCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props} transform={transform} redirect={'list'}>
      <SimpleForm resolver={createSchema}>
        <ReferenceArrayInput
          source="docs"
          reference="Doc"
        >
          <SelectArrayInput optionText={DocTitle}  label="resources.docTypes.fields.doc" />
        </ReferenceArrayInput>
        <TextInput label="resources.docTypes.fields.label" source="label" />
        <TextInput label="slug" source="slug" />
      </SimpleForm>
    </Create>
  );
};
