import * as React from "react";

import {
    AutocompleteInput,
    BooleanInput,
    Create,
    CreateProps,
    DateInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    useTranslate
} from "react-admin";
import {Theme, useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import Box from "@mui/material/Box/Box";
import {familyRolesChoices, sexChoices} from "../contsatns/common";
import {CreateFamilyDialog} from "../family/CreateFamilyDialog";
import {FamilyTitle} from "../family/FamilyTitle";
import {createSchema} from "./validation";

const defaultValues = {
    birthday: new Date('1990-01-01'),
};
export const PersonCreate = (props: CreateProps): React.ReactElement => {
    const translate = useTranslate();
    const isXsmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return <Create {...props} redirect={'edit'}>
        <SimpleForm
            resolver={createSchema}
            mode="onBlur"
            reValidateMode="onChange"
            warnWhenUnsavedChanges
            defaultValues={defaultValues}>
            <Grid container spacing={2}>
                {
                    !isXsmall &&
                    <Grid item xs={12}>
                        <Typography variant="h6">{translate('resources.people.create.title1')}</Typography>
                    </Grid>
                }
                <Grid item xs={12} md={'auto'}>
                    <TextInput
                        fullWidth
                        label="resources.people.fields.lastName"
                        source="lastName"/>
                </Grid>
                <Grid item xs={12} md={'auto'}>
                    <TextInput
                        fullWidth
                        label="resources.people.fields.firstName"
                        source="firstName"/>
                </Grid>
                <Grid item xs={12} md={'auto'}>
                    <TextInput
                        fullWidth
                        label="resources.people.fields.surname"
                        source="surname"/>
                </Grid>
                <Box width="100%"/>
                <Grid item xs={12} md={'auto'}>
                    <SelectInput
                        sx={{marginTop: 0}}
                        fullWidth
                        source="sex"
                        label="resources.people.fields.sex"
                        choices={sexChoices}
                        optionText="label"
                        optionValue="value"
                    />
                </Grid>
                <Grid item xs={12} md={'auto'}>
                    <DateInput label="resources.people.fields.birthday" source="birthday"/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">{translate('resources.people.create.title2')}</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <ReferenceInput source="family.id" reference="Family">
                        <AutocompleteInput
                            label="resources.people.fields.family"
                            filterToQuery={searchText => ({label: {contains: searchText, mode: 'Insensitive'}})}
                            createValue={'resources.people.create.createNewFamily'}
                            create={<CreateFamilyDialog/>}
                            sx={{marginTop: 0}}
                            size={'small'}
                            fullWidth
                            optionText={FamilyTitle}/>
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12} md={2}>
                    <SelectInput
                        fullWidth
                        source="familyRole"
                        label="resources.people.fields.familyRole"
                        choices={familyRolesChoices}
                        optionText="label"
                        optionValue="value"
                        sx={{marginTop: 0}}
                    />
                </Grid>
                <Grid item xs={12} md={'auto'}>
                    <BooleanInput label="resources.people.fields.isAdopted" source="isAdopted"/>
                </Grid>
                <Grid item xs={12} md={'auto'}>
                    <BooleanInput label="resources.people.fields.liveSeparately" source="liveSeparately"/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
};
