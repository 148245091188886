import * as React from "react";

import {
    Create,
    SimpleForm,
    CreateProps,
    TextInput,
    PasswordInput,
    SelectArrayInput,
    useTranslate,
    ReferenceArrayInput,
} from "react-admin";

import {ActionTitle} from "../action/ActionTitle";
import {ROLES_OPTIONS} from "../user/RolesOptions";
import {User} from "../api/user/User";
import {createTransformFn} from "../util/createTransformFn";
import {createSchema} from "./validation";

const transform = createTransformFn<User>(['actions']);
export const UserCreate = (props: CreateProps): React.ReactElement => {
    const translate = useTranslate();
    return (
        <Create {...props} transform={transform} redirect={'list'}>
            <SimpleForm resolver={createSchema}>
                <ReferenceArrayInput
                    source="actions"
                    reference="Action"
                >
                    <SelectArrayInput optionText={ActionTitle}/>
                </ReferenceArrayInput>
                <TextInput label={translate('resources.users.fields.firstName')} source="firstName"/>
                <TextInput label={translate('resources.users.fields.lastName')} source="lastName"/>
                <PasswordInput label={translate('resources.users.fields.password')} source="password"/>
                <SelectArrayInput
                    label={translate('resources.users.fields.roles')}
                    source="roles"
                    choices={ROLES_OPTIONS}
                    optionText="label"
                    optionValue="value"
                />
                <TextInput label={translate('resources.users.fields.username')} source="username"/>
            </SimpleForm>
        </Create>
    );
};
