import * as React from "react";
import {FC} from "react";
import {Identifier, SaveButton, SimpleForm, useNotify, useRefresh, useTranslate} from "react-admin";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {createDocSchema} from "../../doc/validation";
import {DocCreateFields} from "../../doc/DocCreateFields";
import {useCreateDoc} from "../../doc/useCreateDoc";

interface Props {
    onClose: () => void
    isOpen: boolean
    personId: Identifier
}

const renderChildren: React.FC = ({children}) => <>{children}</>;

export const DocCreateDialog: FC<Props> = (props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();

    const {handleSubmit} = useCreateDoc({
        onSuccess: () => {
            props.onClose();
            refresh()
            notify('ra.notification.created', {
                type: 'info',
                messageArgs: {smart_count: 1},
            });
        }
    })

    return <Dialog
        open={props.isOpen}
        onClose={props.onClose}>
        <SimpleForm
            resolver={createDocSchema}
            reValidateMode="onChange"
            toolbar={false}
            component={renderChildren}
            onSubmit={handleSubmit}
            values={{person: {id: props.personId}}}>
            <DialogTitle>{translate('resources.contacts.action.create')}</DialogTitle>
            <DialogContent>
                <DocCreateFields personId={props.personId} isDialog/>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{translate('resources.contacts.action.cancel')}</Button>
                <SaveButton
                    label="resources.contacts.action.save"
                    variant="text"
                />
            </DialogActions>
        </SimpleForm>
    </Dialog>
}