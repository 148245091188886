import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import {FamilyStatusList} from "./FamilyStatusList";
import {FamilyStatusCreate} from "./FamilyStatusCreate";
import {FamilyStatusEdit} from "./FamilyStatusEdit";
import {FamilyStatusShow} from "./FamilyStatusShow";

export default {
    name: 'FamilyStatus',
    icon: EscalatorWarningIcon,
    list: FamilyStatusList,
    create: FamilyStatusCreate,
    edit: FamilyStatusEdit,
    show: FamilyStatusShow,
    options: {
        label: 'resources.familyStatuses.name'
    },
};
