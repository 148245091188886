import {useFileUpload} from "../hooks/useFileUpload";
import {Identifier, useCreate, UseCreateOptions, useEditController, useUpdate} from "react-admin";
import {useCallback} from "react";

type Params = UseCreateOptions & { id?: string }
export const useUpdateDoc = ({onSuccess, id}: Params) => {
    const {mutateAsync} = useFileUpload();
    const {record, isLoading} = useEditController({
        id,
        resource: 'Doc',
        redirect: false,
    });
    const [update] = useUpdate();
    const handleSubmit = useCallback(async ({_path, ...values}: any) => {
        let file = values.file;
        if (_path?.rawFile) {
            const newFileData = await mutateAsync(_path.rawFile);
            file = {id: newFileData.data.id};
        }
        await update('Doc',
            {
                id,
                data: {
                    ...values,
                    file
                }
            }, {
                onSuccess
            });
    }, [update, mutateAsync, onSuccess]);
    return {
        handleSubmit,
        record,
        isLoading
    }
}