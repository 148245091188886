import {withLifecycleCallbacks} from "react-admin";
import {replaceCdnPath} from "../util/replaceCdnPath";

export const addDataProviderCallbacks = (provider: any) => withLifecycleCallbacks(provider, [
    {
        resource: "Contact",
    },
    {
        resource: "District",
    },
    {
        resource: "Street",
    },
    {
        resource: "File",
        afterGetOne: async (response) => ({
            ...response,
            data: {
                ...response.data,
                path: replaceCdnPath(response.data.path)
            }
        }),
        afterGetList: async (response) => ({
            ...response,
            data: response.data.map((item) => ({
                ...item,
                path: replaceCdnPath(item.path)
            }))
        }),
        afterGetMany: async (response) => ({
            ...response,
            data: response.data.map((item) => ({
                ...item,
                path: replaceCdnPath(item.path)
            }))
        })
    },
    {
        resource: "Person",
    },
    {
        resource: "PersonAddress",
    }
]);