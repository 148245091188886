import Icon from '@mui/icons-material/FilePresent';
import {DocTypeList} from "./DocTypeList";
import {DocTypeCreate} from "./DocTypeCreate";
import {DocTypeEdit} from "./DocTypeEdit";
import {DocTypeShow} from "./DocTypeShow";

export default {
    name: 'DocType',
    icon: Icon,
    list: DocTypeList,
    create: DocTypeCreate,
    edit: DocTypeEdit,
    show: DocTypeShow,
    options: {
        label: 'resources.docTypes.name'
    },
};