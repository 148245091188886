
import * as yup from "yup";
import polyglotI18nProvider from "ra-i18n-polyglot";
import uaMessages from "./ua";


export const i18nProvider = polyglotI18nProvider(
    locale => {
        if (locale === 'en') {
            // setLocale({});
            return import('./en').then(messages => messages.default);
        }
        // Always fallback on ukrainian
        // yup.setLocale({
        //     mixed: {
        //         required: 'Обов\'язкове поле',
        //     }
        // });
        return uaMessages;
    },
    'ua',
    [
        {locale: 'ua', name: 'Солов\'їна'},
        {locale: 'en', name: 'English'},
    ]
);