// in src/comments.js
import * as React from 'react';
import {Box, Card, CardContent, CardHeader, Typography} from '@mui/material';
import {
    DateField,
    EditButton,
    FunctionField,
    RecordContextProvider,
    TextField,
    useListContext,
    useTranslate
} from 'react-admin';
import {User} from "../api/user/User";
import {renderRolesField} from "./RolesField";


const MobileGrid = () => {
    const translate = useTranslate();
    const {data, isLoading} = useListContext<User>();

    if (isLoading || data.length === 0) {
        return null;
    }

    return (
        <Box margin="0.5em">
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Card sx={{margin: '0.5rem 0'}}>
                        <CardHeader
                            title={`${record.firstName} ${record.lastName}`}
                            subheader={
                                <>
                                    {translate('resources.users.fields.createdAt',)}
                                    :&nbsp;
                                    <DateField source="createdAt"/> &nbsp;
                                </>
                            }
                            action={<EditButton/>}
                        />
                        <CardContent sx={{pt: 0}}>
                            <Typography variant="body2">
                                {translate(
                                    'resources.users.name',
                                    1
                                )}
                                :&nbsp;
                                <TextField source="username"/>
                            </Typography>
                            <Typography variant="body2">
                                {translate('resources.users.fields.updatedAt',)}
                                :&nbsp;
                                <DateField source="updatedAt"/>
                            </Typography>
                        </CardContent>
                        {record.roles && record.roles.length > 0 && (
                            <CardContent sx={{pt: 0}}>
                                <Typography
                                    variant="body2"
                                    sx={{display: 'inline-flex'}}>
                                    {translate('resources.users.fields.roles')}:&nbsp;
                                </Typography>
                                {renderRolesField(record)}
                            </CardContent>
                        )}
                    </Card>
                </RecordContextProvider>
            ))}
        </Box>
    );
};

export default MobileGrid;
