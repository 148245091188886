import axios from "axios";
import {BASE_URI} from "../contsatns/common";
import {useNotify, useTranslate} from "react-admin";
import {useMutation} from "react-query";

export const useFileUpload = () => {
    const notify = useNotify();
    const translate = useTranslate();
    const mutation = useMutation({
        mutationKey: 'uploadFile',
        mutationFn: async (rawFile: string) =>
            await axios.post(`${BASE_URI}/api/files/upload`, {
                file: rawFile,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // fixme: Get the token from the authentication provider isn't the best way to do it, but it works for now
                    Authorization: localStorage.getItem('credentials'),
                }
            }),
        onError: (error: any) => {
            const errorOptions = {
                variant: 'error',
            };
            if (error?.message) {
                notify(error.message, errorOptions);
            }
        }
    });

    return mutation
}