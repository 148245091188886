import * as React from "react";
import {FC, useMemo} from "react";
import {Identifier, SaveButton, SimpleForm, useNotify, useRefresh, useTranslate} from "react-admin";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {ActionCreateFields} from "../../action/ActionCreateFields";
import {createActionSchema} from "../../action/validation";
import {useCreateAction} from "../../action/useCreateAction";

interface Props {
    onClose: () => void
    open: boolean
    personId: Identifier
}

const renderChildren: React.FC = ({children}) => <>{children}</>;


export const ActionsCreateDialog: FC<Props> = (props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();

    const {handleSubmit} = useCreateAction({
        onSuccess: () => {
            props.onClose();
            refresh()
            notify('ra.notification.created', {
                type: 'info',
                messageArgs: {smart_count: 1},
            });
        }
    });

    const defaultValues = useMemo(() => ({
        person: {
            id: props.personId
        },
        date: new Date(),
    }), [props.personId])

    return <Dialog
        open={props.open}
        onClose={props.onClose}>
        <SimpleForm
            defaultValues={defaultValues}
            resolver={createActionSchema}
            reValidateMode="onChange"
            toolbar={false}
            component={renderChildren}
            onSubmit={handleSubmit}>
            <DialogTitle>{translate('resources.actions.create.title')}</DialogTitle>{/*todo localisation*/}
            <DialogContent>
                <ActionCreateFields personId={props.personId} isDialog/>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{translate('resources.contacts.action.cancel')}</Button>
                <SaveButton
                    label="resources.contacts.action.save"
                    variant="text"
                />
            </DialogActions>
        </SimpleForm>
    </Dialog>
}