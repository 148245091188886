import FaceTwoToneIcon from '@mui/icons-material/FaceTwoTone';
import {PersonList} from "./PersonList";
import {PersonCreate} from "./PersonCreate";
import {PersonEdit} from "./PersonEdit/PersonEdit";
import {PersonShow} from "./PersonShow";
import {Person} from "../api/person/Person";

const PersonResource = {
    name: 'Person',
    icon: FaceTwoToneIcon,
    list: PersonList,
    create: PersonCreate,
    edit: PersonEdit,
    show: PersonShow,
    options: {
        label: 'resources.people.name'
    },
};

export default PersonResource;
