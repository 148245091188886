import Icon from '@mui/icons-material/DocumentScanner';
import {DocList} from "./DocList";
import {DocCreate} from "./DocCreate";
import {DocEdit} from "./DocEdit";
import {DocShow} from "./DocShow";

export default {
    name: 'Doc',
    icon: Icon,
    list: DocList,
    create: DocCreate,
    edit: DocEdit,
    show: DocShow,
    options: {
        label: 'resources.docs.name'
    },
};
