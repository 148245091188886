import Icon from '@mui/icons-material/Contacts';
import {ContactList} from "./ContactList";
import {ContactCreate} from "./ContactCreate";
import {ContactEdit} from "./ContactEdit";
import {ContactShow} from "./ContactShow";

export default {
    name: 'Contact',
    icon: Icon,
    list: ContactList,
    create: ContactCreate,
    edit: ContactEdit,
    show: ContactShow,
    options: {
        label: 'resources.contacts.name'
    },
};
