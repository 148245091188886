import * as React from "react";
import {
  Edit,
  SimpleForm,
  EditProps,
  SelectInput,
  ReferenceInput,
  TextInput,
} from "react-admin";
import { PersonTitle } from "../person/PersonTitle";

export const ContactEdit = (props: EditProps): React.ReactElement => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <SelectInput
          source="contactType"
          label="Contact Type"
          choices={[
            { label: "resources.contacts.fields.Phone", value: "Phone" },
            { label: "resources.contacts.fields.Email", value: "Email" },
          ]}
          optionText="label"
          optionValue="value"
        />
        <ReferenceInput source="person.id" reference="Person" label="Person">
          <SelectInput optionText={PersonTitle} />
        </ReferenceInput>
        <TextInput label="Value" source="value" />
      </SimpleForm>
    </Edit>
  );
};
