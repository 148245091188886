import * as React from "react";
import { List, Datagrid, ListProps, DateField, TextField } from "react-admin";
import Pagination from "../Components/Pagination";

export const FamilyStatusList = (props: ListProps): React.ReactElement => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Family Statuses"}
      perPage={50}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show">
        <DateField source="createdAt" label="resources.familyStatuses.fields.createdAt" />
        <TextField label="ID" source="id" />
        <TextField label="resources.familyStatuses.fields.labelStatuses" source="label" />
        <TextField label="Slug" source="slug" />
        <DateField source="updatedAt" label="resources.familyStatuses.fields.updatedAtStatuses" />
      </Datagrid>
    </List>
  );
};
