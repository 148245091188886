import {
    Confirm,
    Datagrid, Identifier,
    ReferenceManyField,
    SelectField, SimpleList,
    TextField, useCreatePath,
    useRecordContext,
    useTranslate,
} from "react-admin";
import * as React from "react";
import {ContactsEditDialog} from "../components/ContactsEditDialog";
import {contactTypeChoices} from "../../contsatns/common";
import {DataGridHeader} from "../components/DataGridHeader";
import {DataGridBody} from "../components/DataGridBody";
import {useEditDialog} from "./useEditDialog";
import Typography from "@mui/material/Typography/Typography";
import {IconButton, Stack} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {ContactsCreateDialog} from "../components/ContactsCreateDialog";
import {useDeleteConfirm} from "./useDeleteConfirm";
import {ListIconWrapper} from "../components/ListIconWrapper";
import {useCallback} from "react";
import {RaRecord} from "ra-core";
import {Link as RouterLink} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {useListRightIcon} from "./useListRightIcon";

interface Props {
    isMobile?: boolean;
}

export const PersonContacts = ({isMobile}: Props) => {
    const {id} = useRecordContext();
    const {
        closeEditDialog,
        openEditDialog,
        isOpenEditDialog,
        editedId,
        openCreateDialog,
        isOpenCreateDialog,
        closeCreateDialog,
    } = useEditDialog();
    const {
        closeDeleteDialog,
        confirmDelete,
        isOpenDeleteDialog,
        deleteEntity
    } = useDeleteConfirm('Contact');
    const translate = useTranslate();
    const rightIcon = useListRightIcon('Contact');
    return <>
        <Confirm
            isOpen={isOpenDeleteDialog}
            title={`ra.message.delete_conf_title`}
            content={`ra.message.delete_content`}
            onConfirm={confirmDelete}
            onClose={closeDeleteDialog}
        />
        <Stack direction="row" spacing={1}>
            <Typography variant="h6">{translate('resources.people.create.title3')}</Typography>
            <IconButton size={'small'} onClick={openCreateDialog}>
                <AddIcon/>
            </IconButton>
        </Stack>
        <ReferenceManyField label="Contacts" reference="Contact" target="person">
            {
                isMobile ?
                    <ListIconWrapper>
                        <SimpleList
                            linkType={false}
                            primaryText="%{value}"
                            secondaryText={<SelectField
                                source="contactType"
                                label={translate('resources.contacts.fields.contactType')}
                                choices={contactTypeChoices}
                                optionText={'label'}
                                optionValue={'value'}
                            />}
                            rightIcon={rightIcon}
                        />
                    </ListIconWrapper> :
                    <Datagrid
                        header={<DataGridHeader/>}
                        body={<DataGridBody onEditClick={openEditDialog} onDeleteClick={deleteEntity}/>}
                    >
                        <TextField source="value" label={translate('resources.contacts.fields.value')}/>
                        <SelectField
                            source="contactType"
                            label={translate('resources.contacts.fields.contactType')}
                            choices={contactTypeChoices}
                            optionText={'label'}
                            optionValue={'value'}
                        />
                        <TextField source="id" label={translate('resources.contacts.fields.id')}/>
                    </Datagrid>
            }
        </ReferenceManyField>
        {
            editedId &&
            <ContactsEditDialog
                id={editedId}
                onClose={closeEditDialog}
                open={isOpenEditDialog}/>
        }
        <ContactsCreateDialog
            onClose={closeCreateDialog}
            open={isOpenCreateDialog}
            personId={id}
        />
    </>
}