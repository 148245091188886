import React, {useCallback} from "react";
import {Confirm, Identifier, useCreatePath, useEditContext, useGetMany,} from "react-admin";
import {EmptyTitleDataGrid} from "../../Components/EmptyTitleDataGrid";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDeleteConfirm} from "./useDeleteConfirm";


type ActionsProps = {
    id: string
    onDeleteClick: (id: string) => void
};
const Actions = React.memo(({id, onDeleteClick}: ActionsProps) => {
    const createPath = useCreatePath();
    const handleClick = useCallback(() => {
        onDeleteClick(id);
    }, [id]);
    return (
        <>
            <Link
                target={'_blank'}
                to={createPath({resource: 'File', id, type: 'show'})}>
                <IconButton sx={{color: 'rgba(255, 255, 255, 0.54)'}}>
                    <InfoIcon/>
                </IconButton>
            </Link>
            <IconButton
                sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                onClick={handleClick}>
                <DeleteIcon/>
            </IconButton>
        </>
    );
});

export const Photos: React.FC = () => {
    const {record} = useEditContext();
    const {data} = useGetMany('File', {
        ids: record.photosIds
    }, {
        enabled: !!record.photosIds
    });
    const {
        isOpenDeleteDialog,
        closeDeleteDialog,
        confirmDelete,
        openDeleteDialog,
        deleteEntity,
    } = useDeleteConfirm('File');

    return (
        <>
            <Confirm
                isOpen={isOpenDeleteDialog}
                title={`ra.message.delete_conf_title`}
                content={`ra.message.delete_content`}
                onConfirm={confirmDelete}
                onClose={closeDeleteDialog}
            />
            {
                (!record.photosIds || !data?.length) ?
                    <EmptyTitleDataGrid/> :
                    <ImageList
                        sx={{width: '100%', height: 400}}
                        cols={3}>
                        {
                            data.map((item: any) => {
                                return (
                                    <ImageListItem key={item.id}>
                                        <img
                                            src={item.path}
                                            alt={item.id}
                                            loading="lazy"
                                        />
                                        <ImageListItemBar
                                            title={item.id}
                                            actionIcon={<Actions id={item.id} onDeleteClick={deleteEntity}/>}
                                        />
                                    </ImageListItem>
                                );
                            })
                        }
                    </ImageList>
            }
        </>
    );
}