import * as React from "react";

import {
  Show,
  SimpleShowLayout,
  ShowProps,
  DateField,
  TextField,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
} from "react-admin";

import { DOCTYPE_TITLE_FIELD } from "./DocTypeTitle";
import { FILE_TITLE_FIELD } from "../file/FileTitle";
import { PERSON_TITLE_FIELD } from "../person/PersonTitle";

export const DocTypeShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DateField source="createdAt" label="resources.docTypes.fields.createdAt" />
        <TextField label="ID" source="id" />
        <TextField label="resources.docTypes.fields.label" source="label" />
        <TextField label="slug" source="slug" />
        <DateField source="updatedAt" label="resources.docTypes.fields.updatedAt" />
        <ReferenceManyField label="resources.docTypes.fields.docType" reference="Doc" target="DocTypeId" >
          <Datagrid rowClick="show">
            <DateField source="createdAt" label="resources.docTypes.fields.createdAt" />
            <TextField label="resources.docTypes.fields.docSerial" source="docSerial" />
            <ReferenceField
              label="resources.docTypes.fields.docType"
              source="doctype.id"
              reference="DocType"
            >
              <TextField source={DOCTYPE_TITLE_FIELD} />
            </ReferenceField>
            <ReferenceField label="resources.docTypes.fields.file" source="file.id" reference="File">
              <TextField source={FILE_TITLE_FIELD} />
            </ReferenceField>
            <TextField label="ID" source="id" />
            <ReferenceField
              label="resources.docTypes.fields.person"
              source="person.id"
              reference="Person"
            >
              <TextField source={PERSON_TITLE_FIELD} />
            </ReferenceField>
            <DateField source="updatedAt" label="resources.docTypes.fields.updatedAt" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
