import * as React from "react";
import {
    AutocompleteInput,
    ImageField,
    ImageInput,
    ReferenceInput,
    SimpleForm,
    useNotify,
    useRedirect, useTranslate,
} from "react-admin";

import {PersonTitle} from "../person/PersonTitle";
import {useFileUpload} from "../hooks/useFileUpload";
import {createSchema} from "./validation";
import {useFileSizeValidation} from "../hooks/useFileSizeValidation";

export const FileCreate = (): React.ReactElement => {
    const {mutateAsync} = useFileUpload();
    const redirect = useRedirect();
    const fileSizeValidation = useFileSizeValidation();

    const handleSubmit = async (values: any,) => {
        return await new Promise(resolve => {
            mutateAsync(values.path.rawFile, {
                onSuccess: () => {
                    resolve('ok');
                    redirect('/File');
                },
                onError: () => {
                    resolve('error')
                }
            })
        });
    };
    return (
        <>
            <SimpleForm onSubmit={handleSubmit} resolver={createSchema}>
                <ReferenceInput source="person.id" reference="Person">
                    <AutocompleteInput
                        label="resources.files.fields.person"
                        filterToQuery={searchText => ({lastName: {contains: searchText, mode: 'Insensitive'},})}
                        size={'small'}
                        optionText={PersonTitle}/>
                </ReferenceInput>
                <ImageInput label="resources.files.fields.path" source="path" {...fileSizeValidation}>
                    <ImageField source="src" title="title"/>
                </ImageInput>
            </SimpleForm>
        </>
    );
};
