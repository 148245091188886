import * as React from "react";

import {
  Create,
  SimpleForm,
  CreateProps,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
} from "react-admin";

import { FamilyTitle } from "../family/FamilyTitle";
import {createSchema} from "./validation";
import {createTransformFn} from "../util/createTransformFn";
import {FamilyStatus} from "../api/familyStatus/FamilyStatus";

const transform = createTransformFn<FamilyStatus>(['families']);
export const FamilyStatusCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props} transform={transform} redirect={'list'}>
      <SimpleForm resolver={createSchema}>
        <ReferenceArrayInput
          source="families"
          reference="Family"
        >
          <SelectArrayInput optionText={FamilyTitle} label="resources.familyStatuses.fields.family" />
        </ReferenceArrayInput>
        <TextInput label="resources.familyStatuses.fields.labelStatuses" source="label" />
        <TextInput label="Slug" source="slug" />
      </SimpleForm>
    </Create>
  );
};
