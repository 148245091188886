import * as React from "react";

import {
  Edit,
  SimpleForm,
  EditProps,
  ReferenceInput,
  SelectInput,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";

import { CityTitle } from "../city/CityTitle";
import { PersonAddressTitle } from "../personAddress/PersonAddressTitle";
import { StreetTitle } from "../street/StreetTitle";

export const DistrictEdit = (props: EditProps): React.ReactElement => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput source="city.id" reference="City" label="City">
          <SelectInput optionText={CityTitle} />
        </ReferenceInput>
        <TextInput label="Label" source="label" />
        <ReferenceArrayInput
          source="personAddresses"
          reference="PersonAddress"
          parse={(value: any) => value && value.map((v: any) => ({ id: v }))}
          format={(value: any) => value && value.map((v: any) => v.id)}
        >
          <SelectArrayInput optionText={PersonAddressTitle} />
        </ReferenceArrayInput>
        <TextInput label="Slug" source="slug" />
        <ReferenceArrayInput
          source="streets"
          reference="Street"
          parse={(value: any) => value && value.map((v: any) => ({ id: v }))}
          format={(value: any) => value && value.map((v: any) => v.id)}
        >
          <SelectArrayInput optionText={StreetTitle} />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};
