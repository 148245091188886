import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    ShowProps,
    DateField,
    TextField,
    ReferenceField, ImageField, WrapperField,
} from "react-admin";
import {DOCTYPE_TITLE_FIELD} from "../docType/DocTypeTitle";
import {FILE_TITLE_FIELD} from "../file/FileTitle";
import {PERSON_TITLE_FIELD} from "../person/PersonTitle";

export const DocShow = (props: ShowProps): React.ReactElement => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <DateField source="createdAt" label="resources.docs.fields.createdAt"/>
                <TextField label="resources.docs.fields.docSerial" source="docSerial"/>
                <ReferenceField
                    label="resources.docs.fields.docType"
                    source="doctype.id"
                    reference="DocType"
                >
                    <TextField source={DOCTYPE_TITLE_FIELD}/>
                </ReferenceField>
                <ReferenceField label="resources.docs.fields.file" source="file.id" reference="File">
                    <WrapperField>
                        <TextField source={FILE_TITLE_FIELD}/>
                        <ImageField source="path"/>
                    </WrapperField>
                </ReferenceField>
                <TextField label="ID" source="id"/>
                <ReferenceField label="resources.docs.fields.person" source="person.id" reference="Person">
                    <TextField source={PERSON_TITLE_FIELD}/>
                </ReferenceField>
                <DateField source="updatedAt" label="resources.docs.fields.updatedAt"/>
            </SimpleShowLayout>
        </Show>
    );
};
