import * as React from "react";
import {
    DatagridConfigurable,
    DateField,
    FunctionField,
    List,
    ListProps,
    SearchInput,
    TextField, TextInput,
    useTranslate,
} from "react-admin";
import Pagination from "../Components/Pagination";
import {Theme, useMediaQuery} from '@mui/material';
import MobileGrid from './MobileGrid';
import {renderRolesField} from "./RolesField";

const postFilters = [
    <TextInput label="resources.users.fields.firstName" source="firstName.contains" translate={'yes'} alwaysOn/>,
    <TextInput label="resources.users.fields.lastName" source="lastName.contains" translate={'yes'} alwaysOn/>,
];

const filterDefaultValues = {
    firstName: {
        mode: 'Insensitive'
    },
    lastName: {
        mode: 'Insensitive'
    },
};

export const UserList = (props: ListProps): React.ReactElement => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const translate = useTranslate();

    return (
        <List
            {...props}
            bulkActionButtons={false}
            title={"Users"}
            perPage={50}
            pagination={<Pagination/>}
            disableSyncWithLocation={true}
            filterDefaultValues={filterDefaultValues}
            filters={postFilters}
            debounce={1000}
        >
            {isXsmall ? (
                <MobileGrid/>
            ) : (
                <DatagridConfigurable omit={['id']} rowClick="show">
                    <TextField label={translate('resources.users.fields.id')} source="id"/>
                    <DateField source="createdAt" label={translate('resources.users.fields.createdAt')}/>
                    <TextField label={translate('resources.users.fields.firstName')} source="firstName"/>
                    <TextField label={translate('resources.users.fields.lastName')} source="lastName"/>
                    <FunctionField
                        label={translate('resources.users.fields.roles')}
                        source="roles"
                        render={renderRolesField}/>
                    <DateField label={translate('resources.users.fields.updatedAt')} source="updatedAt"/>
                    <TextField label={translate('resources.users.fields.username')} source="username"/>
                </DatagridConfigurable>
            )}
        </List>
    );
};
