export const familyRolesChoices = [
    { label: "resources.families.data.father", value: "Father" },
    { label: "resources.families.data.mother", value: "Mother" },
    { label: "resources.families.data.son", value: "Son" },
    { label: "resources.families.data.daughter", value: "Daughter" },
    { label: "resources.families.data.grandmother", value: "Grandmother" },
    { label: "resources.families.data.grandfather", value: "Grandfather" },
];
export const sexChoices = [
    { label: "resources.families.data.male", value: "Male" },
    { label: "resources.families.data.female", value: "Female" },
];
export const contactTypeChoices = [
    {label: "resources.contacts.data.phone", value: "Phone"},
    {label: "resources.contacts.data.email", value: "Email"},
];

export const BASE_URI = process.env.REACT_APP_SERVER_URL;
export const CDN_URI = process.env.REACT_APP_CDN_URL || '';

export const MAX_FILE_SIZE = Number(process.env.REACT_APP_MAX_FILE_SIZE) || 1000000;
