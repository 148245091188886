import {useRecordContext} from "react-admin";
import {User} from "../api/user/User";
import {Chip} from "@mui/material";
import * as React from "react";

export const renderRolesField = (record: User) => {
    return <>{
        record?.roles.map(
            (role, i) =>
                <Chip
                    key={i}
                    sx={{marginRight: 0.5}}
                    size={'small'}
                    label={role}
                />
        )
    }</>;
};