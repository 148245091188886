import * as React from "react";

import {
  Create,
  SimpleForm,
  CreateProps,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
} from "react-admin";

import { ActionTitle } from "../action/ActionTitle";
import { ActionType } from "../api/actionType/ActionType";
import {createTransformFn} from "../util/createTransformFn";
import {createSchema} from "./validation";


const transform = createTransformFn<ActionType>(['actions']);
export const ActionTypeCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props} transform={transform} redirect={'list'}>
      <SimpleForm resolver={createSchema}>
        <ReferenceArrayInput
          source="actions"
          reference="Action"
        >
          <SelectArrayInput optionText={ActionTitle} label="resources.actionTypes.fields.actions" />
        </ReferenceArrayInput>
        <TextInput label="resources.actionTypes.fields.label" source="label" />
        <TextInput label="slug" source="slug" />
      </SimpleForm>
    </Create>
  );
};
