import React, {useEffect, useState} from "react";
import {Admin, CustomRoutes, DataProvider, Resource} from "react-admin";
import {lightTheme} from "./theme/theme";
import "./App.scss";
import countries from "./country/index";
import states from "./state/index";
import users from "./user/index";
import commons from "./common/index";
import cities from "./city/index";
import districts from "./district/index";
import streets from "./street/index";
import files from "./file/index";
import families from "./family/index";
import people from "./person/index";
import docs from "./doc/index";
import contacts from "./contact/index";
import docTypes from "./docType/index";
import familyStatuses from "./familyStatus/index";
import personAddresses from "./personAddress/index";
import actionTypes from "./actionType/index";
import actions from "./action/index";

import {jwtAuthProvider} from "./auth-provider/ra-auth-jwt";
import {Layout, Login} from './layout';
import buildGraphQLProvider from "./data-provider/graphqlDataProvider";
import {i18nProvider} from "./i18n/i18nProvider";
import {addDataProviderCallbacks} from "./data-provider/dataProviderCallbacks";
import {HomeList} from "./pages/HomeList";
import { Route } from "react-router-dom";

const App = (): React.ReactElement => {
    const [dataProvider, setDataProvider] = useState<DataProvider | null>(null);
    useEffect(() => {
        buildGraphQLProvider
            .then((provider: any) => {
                setDataProvider(() => addDataProviderCallbacks(provider));
            })
            .catch((error: any) => {
                console.log(error);
            });
    }, []);
    if (!dataProvider) {
        return <div>Loading</div>;
    }
    return (
        <div className="App">
            <Admin
                title={"Core"}
                dataProvider={dataProvider}
                authProvider={jwtAuthProvider}
                theme={lightTheme}
                dashboard={HomeList}
                loginPage={Login}
                layout={Layout}
                disableTelemetry
                i18nProvider={i18nProvider}
            >
                <Resource {...people}/>
                <Resource {...personAddresses}/>
                <Resource {...families}/>
                <Resource {...familyStatuses}/>
                <Resource {...contacts}/>
                <Resource {...countries}/>
                <Resource {...states}/>
                <Resource {...commons}/>
                <Resource {...cities}/>
                <Resource {...districts}/>
                <Resource {...streets}/>
                <Resource {...files}/>
                <Resource {...docs}/>
                <Resource {...docTypes}/>
                <Resource {...actions}/>
                <Resource {...actionTypes}/>
                <Resource {...users}/>
                <CustomRoutes>
                    <Route path="/list" element={<HomeList {...{} as any}/>} />
                </CustomRoutes>
            </Admin>
        </div>
    );
};

export default App;
