import * as React from "react";
import {
  List,
  Datagrid,
  ListProps,
  ReferenceField,
  TextField,
  DateField,
} from "react-admin";
import Pagination from "../Components/Pagination";
import { ACTIONTYPE_TITLE_FIELD } from "../actionType/ActionTypeTitle";
import { PERSON_TITLE_FIELD } from "../person/PersonTitle";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const ActionList = (props: ListProps): React.ReactElement => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Actions"}
      perPage={50}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show">
        <ReferenceField
          label="resources.actions.fields.actionType"
          source="actionType.id"
          reference="ActionType"
        >
          <TextField source={ACTIONTYPE_TITLE_FIELD} />
        </ReferenceField>
        <DateField source="createdAt" label="resources.actions.fields.actionType" />
        <TextField label="resources.actions.fields.date" source="date" />
        <TextField label="resources.actions.fields.description" source="description" />
        <TextField label="ID" source="id" />
        <ReferenceField label="resources.actions.fields.person" source="person.id" reference="Person">
          <TextField source={PERSON_TITLE_FIELD} />
        </ReferenceField>
        <DateField source="updatedAt" label="resources.actions.fields.updatedAt" />
        <ReferenceField label="resources.actions.fields.user" source="user.id" reference="User">
          <TextField source={USER_TITLE_FIELD} />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
