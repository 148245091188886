import * as React from "react";
import {
  Create,
  SimpleForm,
  CreateProps,
  SelectInput,
  ReferenceInput,
  TextInput,
} from "react-admin";
import { PersonTitle } from "../person/PersonTitle";

export const ContactCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props}>
      <SimpleForm>
        <SelectInput
          source="contactType"
          label="resources.contacts.fields.contactType"
          choices={[
            { label: "resources.contacts.fields.Phone", value: "Phone" },
            { label: "resources.contacts.fields.Email", value: "Email" },
          ]}
          optionText="label"
          optionValue="value"
        />
        <ReferenceInput source="person.id" reference="Person" >
          <SelectInput optionText={PersonTitle} label="resources.contacts.fields.person" />
        </ReferenceInput>
        <TextInput label="resources.contacts.fields.value" source="value" />
      </SimpleForm>
    </Create>
  );
};
