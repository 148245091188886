export const ROLES = [
  {
    name: "user",
    displayName: "User",
  },
  {
    name: "admin",
    displayName: "Admin",
  },
];
