import {useCallback, useState} from "react";

export const useEditDialog = () => {
    const [isOpenEditDialog, setOpen] = useState(false);
    const [isOpenCreateDialog, setCreateOpen] = useState(false);
    const [editedId, setEditedId] = useState<string | null>(null);

    const openEditDialog = useCallback((id: string) => {
        setEditedId(id);
        setOpen(true);
    }, []);
    const closeEditDialog = useCallback(() => {
        setOpen(false);
    }, []);

    const openCreateDialog = useCallback(() => {
        setCreateOpen(true);
    }, []);
    const closeCreateDialog = useCallback(() => {
        setCreateOpen(false);
        setEditedId(null);
    }, []);

    return {
        isOpenEditDialog,
        editedId,
        openEditDialog,
        closeEditDialog,
        isOpenCreateDialog,
        openCreateDialog,
        closeCreateDialog,
    };
}