import * as yup from "yup";
import {City} from "../api/city/City";
import {yupResolver} from "@hookform/resolvers/yup";
import {User} from "../api/user/User";

export const createSchema = yupResolver(yup
    .object<User>()
    .shape({
        // actions: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        roles: yup.array().of(yup.string().required()).required(),
        username: yup.string().required(),
        password: yup.string().required(),

    })
    .required()
);
