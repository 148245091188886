import * as React from "react";

import {
  Show,
  SimpleShowLayout,
  ShowProps,
  DateField,
  TextField,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
  BooleanField,
} from "react-admin";

import { FAMILY_TITLE_FIELD } from "./FamilyTitle";
import { FAMILYSTATUS_TITLE_FIELD } from "../familyStatus/FamilyStatusTitle";

export const FamilyShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DateField source="createdAt" label="resources.families.fields.createdAt" />
        <TextField label="ID" source="id" />
        <TextField label="resources.families.fields.label" source="label" />
        <ReferenceField
          label="resources.families.fields.status"
          source="status.id"
          reference="FamilyStatus"
        >
          <TextField source={FAMILYSTATUS_TITLE_FIELD} />
        </ReferenceField>
        <DateField source="updatedAt" label="resources.families.fields.updatedAt" />
        <ReferenceManyField reference="Person" target="FamilyId" label="resources.families.fields.person">
          <Datagrid rowClick="show">
            <TextField label="resources.families.fields.birthday" source="birthday" />
            <DateField source="createdAt" label="resources.families.fields.createdAt" />
            <ReferenceField
              label="resources.families.fields.family"
              source="family.id"
              reference="Family"
            >
              <TextField source={FAMILY_TITLE_FIELD} />
            </ReferenceField>
            <TextField label="resources.families.fields.familyRole" source="familyRole" />
            <TextField label="resources.families.fields.firstName" source="firstName" />
            <TextField label="ID" source="id" />
            <BooleanField label="resources.families.fields.isAdopted" source="isAdopted" />
            <TextField label="resources.families.fields.lastName" source="lastName" />
            <BooleanField label="resources.families.fields.liveSeparately" source="liveSeparately" />
            <TextField label="resources.families.fields.sex" source="sex" />
            <TextField label="resources.families.fields.surname" source="surname" />
            <DateField source="updatedAt" label="resources.families.fields.updatedAt" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
