import * as React from "react";

import {
    BooleanField,
    DatagridConfigurable,
    DateField,
    List,
    ListProps,
    ReferenceField,
    SelectField,
    SimpleList,
    TextField,
    TextInput,
    useTranslate,
} from "react-admin";
import '../i18n/yupLocale';
import Pagination from "../Components/Pagination";
import {FAMILY_TITLE_FIELD} from "../family/FamilyTitle";
import {Theme, useMediaQuery} from "@mui/material";
import {familyRolesChoices, sexChoices} from "../contsatns/common";

const postFilters = [
    <TextInput label="resources.people.fields.lastName" source="lastName.contains" translate={'yes'} alwaysOn/>,
    <TextInput label="resources.people.fields.firstName" source="firstName.contains" translate={'yes'}/>,
];

const filterDefaultValues = {
    firstName: {
        mode: 'Insensitive'
    },
    lastName: {
        mode: 'Insensitive'
    },
};
export const PersonList = (props: ListProps): React.ReactElement => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const translate = useTranslate();
    return (
        <List
            {...props}
            bulkActionButtons={false}
            title={"People"}
            perPage={50}
            pagination={<Pagination/>}
            disableSyncWithLocation={true}
            filters={postFilters}
            filterDefaultValues={filterDefaultValues}
        >
            {isXsmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => `${record.lastName} ${record.firstName}`}
                    secondaryText={(record) => <>
                        {record.familyRole}{translate('resources.people.conjunctions.inFamily')}
                        <ReferenceField label="resources.people.fields.family" source="family.id" reference="Family"/>
                    </>}
                    tertiaryText={() => <DateField label="resources.people.fields.birthday" source="birthday"/>}
                    title={"People"}
                />
            ) : (
                <DatagridConfigurable rowClick="edit">
                    <TextField label="resources.people.fields.id" source="id"/>
                    <TextField label="resources.people.fields.lastName" source="lastName"/>
                    <TextField label="resources.people.fields.firstName" source="firstName"/>
                    <TextField label="resources.people.fields.surname" source="surname"/>
                    <SelectField
                        source="familyRole"
                        label="resources.people.fields.familyRole"
                        choices={familyRolesChoices}
                        optionText="label"
                        optionValue="value"
                    />
                    <DateField label="resources.people.fields.birthday" source="birthday"/>
                    <SelectField
                        source="sex"
                        label="resources.people.fields.sex"
                        choices={sexChoices}
                        optionText="label"
                        optionValue="value"
                    />
                    <DateField source="createdAt" label="resources.people.fields.createdAt"/>
                    <ReferenceField label="resources.people.fields.family" source="family.id" reference="Family">
                        <TextField source={FAMILY_TITLE_FIELD}/>
                    </ReferenceField>
                    <BooleanField label="resources.people.fields.isAdopted" source="isAdopted"/>
                    <BooleanField label="resources.people.fields.liveSeparately" source="liveSeparately"/>
                    <DateField source="updatedAt" label="resources.people.fields.updatedAt"/>
                </DatagridConfigurable>
            )}
        </List>
    );
};
