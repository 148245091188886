import * as yup from "yup";
import {Contact} from "../api/contact/Contact";

export const schema = yup
    .object<Contact>()
    .shape({
        contactType: yup.string().required(),
        value: yup.string().required(),
    })
    .required();
