import {Identifier, useCreatePath} from "react-admin";
import {useCallback} from "react";
import {RaRecord} from "ra-core";
import {Link as RouterLink} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import * as React from "react";

export const useListRightIcon = (resource: 'PersonAddress' | 'Doc' | 'Contact') => {
    const createPath = useCreatePath();
    const rightIcon = useCallback((record: RaRecord, id: Identifier) => (
        <RouterLink
            to={createPath({
                resource,
                id,
                type: "edit",
            })}>
            <EditIcon/>
        </RouterLink>
    ), [createPath]);
    return rightIcon;
}