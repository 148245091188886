import {
    ReferenceInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    useEditController,
    useNotify,
    useTranslate,
    useUpdate,
} from "react-admin";
import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {PersonTitle} from "../PersonTitle";
import {contactTypeChoices} from "../../contsatns/common";
import {yupResolver} from "@hookform/resolvers/yup";
import {schema} from "../../contact/validation";
import {useCallback} from "react";


const resolver = yupResolver(schema);

interface Props {
    onClose: () => void;
    id: string
    open: boolean
}

const renderChildren: React.FC = ({children}) => <>{children}</>;

export const ContactsEditDialog = (props: Props) => {
    const {record, save} = useEditController({
        resource: 'Contact',
        id: props.id,
        redirect: false,
    });
    const translate = useTranslate();
    const [update] = useUpdate();

    const notify = useNotify();

    const handleSubmit = useCallback((values) => {
        update('Contact', {id: props.id, data: values});
        save!(values, {
            onSuccess: data => {
                props.onClose();
                notify('ra.notification.edited', {
                    type: 'info',
                    messageArgs: {smart_count: 1},
                });
            }
        })
    }, [props.id]);

    return <Dialog
        open={props.open}
        onClose={props.onClose}>
        <SimpleForm
            resolver={resolver}
            reValidateMode="onChange"
            record={record}
            toolbar={false}
            component={renderChildren}
            onSubmit={handleSubmit}
        >
            <DialogTitle>{translate('resources.contacts.action.edit')}</DialogTitle>
            <DialogContent>
                <ReferenceInput
                    source="person.id"
                    reference="Person"
                    label="resources.contacts.fields.person">
                    <SelectInput
                        disabled
                        fullWidth
                        optionText={PersonTitle}/>
                </ReferenceInput>
                <SelectInput
                    source="contactType"
                    label="resources.contacts.fields.contactType"
                    choices={contactTypeChoices}
                    optionText="label"
                    optionValue="value"
                    fullWidth
                />
                <TextInput label="resources.contacts.fields.value" source="value" fullWidth/>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{translate('resources.contacts.action.cancel')}</Button>
                <SaveButton
                    label="resources.contacts.action.save"
                    variant="text"
                />
            </DialogActions>
        </SimpleForm>
    </Dialog>
}