import FlagIcon from '@mui/icons-material/Flag';
import {StateList} from "./StateList";
import {StateCreate} from "./StateCreate";
import {StateEdit} from "./StateEdit";
import {StateShow} from "./StateShow";

export default {
    name: 'State',
    icon: FlagIcon,
    list: StateList,
    create: StateCreate,
    edit: StateEdit,
    show: StateShow,
    options: {
        label: 'resources.states.name'
    },
};