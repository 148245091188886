import * as React from "react";

import {
  Show,
  SimpleShowLayout,
  ShowProps,
  DateField,
  TextField,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
} from "react-admin";

import { ACTIONTYPE_TITLE_FIELD } from "./ActionTypeTitle";
import { PERSON_TITLE_FIELD } from "../person/PersonTitle";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const ActionTypeShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DateField source="createdAt" label="resources.actionTypes.fields.createdAt" />
        <TextField label="ID" source="id" />
        <TextField label="resources.actionTypes.fields.label" source="label" />
        <TextField label="Slug" source="slug" />
        <DateField source="updatedAt" label="resources.actionTypes.fields.updatedAt" />
        <ReferenceManyField
          reference="Action"
          target="ActionTypeId"
          label="resources.actionTypes.fields.createdAt"
        >
          <Datagrid rowClick="show">
            <ReferenceField
              label="resources.actionTypes.fields.actionType"
              source="actiontype.id"
              reference="ActionType"
            >
              <TextField source={ACTIONTYPE_TITLE_FIELD} />
            </ReferenceField>
            <DateField source="createdAt" label="resources.actionTypes.fields.createdAt" />
            <TextField label="resources.actionTypes.fields.date" source="date" />
            <TextField label="resources.actionTypes.fields.description" source="description" />
            <TextField label="ID" source="id" />
            <ReferenceField
              label="resources.actionTypes.fields.person"
              source="person.id"
              reference="Person"
            >
              <TextField source={PERSON_TITLE_FIELD} />
            </ReferenceField>
            <DateField source="updatedAt" label="resources.actionTypes.fields.updatedAt" />
            <ReferenceField label="resources.actionTypes.fields.user" source="user.id" reference="User">
              <TextField source={USER_TITLE_FIELD} />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
