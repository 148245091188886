import LocationCityIcon from '@mui/icons-material/LocationCity';
import {CityList} from "./CityList";
import {CityCreate} from "./CityCreate";
import {CityEdit} from "./CityEdit";
import {CityShow} from "./CityShow";

export default {
    name: 'City',
    icon: LocationCityIcon,
    list: CityList,
    create: CityCreate,
    edit: CityEdit,
    show: CityShow,
    options: {
        label: 'resources.cities.name'
    },
};
