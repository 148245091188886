import * as React from "react";

import {
    Edit,
    SimpleForm,
    EditProps,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    TextInput,
} from "react-admin";

import {CommonTitle} from "../common/CommonTitle";
import {DistrictTitle} from "../district/DistrictTitle";
import {PersonAddressTitle} from "../personAddress/PersonAddressTitle";
import {StreetTitle} from "../street/StreetTitle";

export const CityEdit = (props: EditProps): React.ReactElement => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput source="common.id" reference="Common" label="Common">
                    <SelectInput optionText={CommonTitle}/>
                </ReferenceInput>
                <ReferenceArrayInput
                    source="districts"
                    reference="District"
                    // queryOptions={{meta: {type: 'edit'}}}
                >
                    <SelectArrayInput
                        optionText={DistrictTitle}
                        parse={(value: any) => value && value.map((v: any) => ({id: v}))}
                        format={(value: any) => value && value.map((v: any) => v.id)}
                    />
                </ReferenceArrayInput>
                <TextInput label="Label" source="label"/>
                <ReferenceArrayInput
                    source="personAddresses"
                    reference="PersonAddress"

                >
                    <SelectArrayInput
                        optionText={PersonAddressTitle}
                        parse={(value: any) => value && value.map((v: any) => ({id: v}))}
                        format={(value: any) => value && value.map((v: any) => v.id)}/>
                </ReferenceArrayInput>
                <TextInput label="Slug" source="slug"/>
                <ReferenceArrayInput
                    source="streets"
                    reference="Street"

                >
                    <SelectArrayInput
                        optionText={StreetTitle}
                        parse={(value: any) => value && value.map((v: any) => ({id: v}))}
                        format={(value: any) => value && value.map((v: any) => v.id)}
                    />
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    );
};
