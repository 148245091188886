import SignpostIcon from '@mui/icons-material/Signpost';
import {StreetList} from "./StreetList";
import {StreetCreate} from "./StreetCreate";
import {StreetEdit} from "./StreetEdit";
import {StreetShow} from "./StreetShow";

export default {
    name: 'Street',
    icon: SignpostIcon,
    list: StreetList,
    create: StreetCreate,
    edit: StreetEdit,
    show: StreetShow,
    options: {
        label: 'resources.streets.name'
    },
};
