import * as React from "react";

import {
    Create,
    SimpleForm,
    CreateProps,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceInput,
    SelectInput, FunctionField, useEditContext, useCreateContext,
} from "react-admin";

import { PersonTitle } from "../person/PersonTitle";
import { FamilyStatusTitle } from "../familyStatus/FamilyStatusTitle";
import {createSchema} from "./validation";
import {createTransformFn} from "../util/createTransformFn";
import {Family} from "../api/family/Family";
import {useWatch} from "react-hook-form";

const JSONStringify = () => {
    const record2 = useWatch({name: 'actions'})
    return <pre>{JSON.stringify(record2, null, 2)}</pre>;
}

const transform = createTransformFn<Family>(['people']);
export const FamilyCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props} transform={transform} redirect={'list'}>
      <SimpleForm resolver={createSchema}>
        <TextInput label="resources.families.fields.labelfamilies" source="label" />
        <ReferenceArrayInput
          source="people"
          reference="Person"
        >
          <SelectArrayInput defaultValue={[]} optionText={PersonTitle} label="resources.families.fields.person"/>
        </ReferenceArrayInput>
        <ReferenceInput
          source="status.id"
          reference="FamilyStatus"
          label="Status"
        >
          <SelectInput optionText={FamilyStatusTitle} label="resources.families.fields.status"/>
        </ReferenceInput>
        <JSONStringify  />
      </SimpleForm>
    </Create>
  );
};
