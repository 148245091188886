import * as React from "react";

import {
  Create,
  SimpleForm,
  CreateProps,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateInput,
} from "react-admin";

import { CityTitle } from "../city/CityTitle";
import { CommonTitle } from "../common/CommonTitle";
import { CountryTitle } from "../country/CountryTitle";
import { DistrictTitle } from "../district/DistrictTitle";
import { PersonTitle } from "../person/PersonTitle";
import { StateTitle } from "../state/StateTitle";
import { StreetTitle } from "../street/StreetTitle";

export const PersonAddressCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput label="resources.personAddresses.fields.apartment" source="appartment" />
        <ReferenceInput source="city.id" reference="City" label="City">
          <SelectInput optionText={CityTitle} label="resources.districts.fields.city" />
        </ReferenceInput>
        <ReferenceInput source="common.id" reference="Common" label="Common">
          <SelectInput optionText={CommonTitle} label="resources.cities.fields.common" />
        </ReferenceInput>
        <ReferenceInput source="country.id" reference="Country" label="Country">
          <SelectInput optionText={CountryTitle} label="resources.states.fields.country" />
        </ReferenceInput>
        <ReferenceInput
          source="district.id"
          reference="District"
          label="resources.states.fields.country"
        >
          <SelectInput optionText={DistrictTitle} label="resources.streets.fields.district" />
        </ReferenceInput>
        <DateInput label="resources.personAddresses.fields.endDate" source="endDate" />
        <TextInput label="resources.personAddresses.fields.house" source="house" />
        <ReferenceInput source="person.id" reference="Person" label="Person">
          <SelectInput optionText={PersonTitle} label="resources.personAddresses.fields.person" />
        </ReferenceInput>
        <DateInput label="resources.personAddresses.fields.startDate" source="startDate" />
        <ReferenceInput source="state.id" reference="State" label="State">
          <SelectInput optionText={StateTitle} label="resources.commons.fields.state" />
        </ReferenceInput>
        <ReferenceInput source="street.id" reference="Street" label="Street">
          <SelectInput optionText={StreetTitle} label="resources.personAddresses.fields.street" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
