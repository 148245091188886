import * as yup from "yup";
import {District} from "../api/district/District";
import {yupResolver} from "@hookform/resolvers/yup";

export const createSchema = yupResolver(yup
    .object<District>()
    .shape({
        label: yup.string().required(),
        slug: yup.string().required(),
        city: yup.object({
            id: yup.string().required(),
        }),
        // streets: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
        // personAddresses: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
    })
    .required()
);
