import * as React from "react";
import {SimpleForm, Title,} from "react-admin";
import {DocEditFields} from "./DocEditFields";
import {useParams} from "react-router-dom";
import {useUpdateDoc} from "./useUpdateDoc";
import {updateDocSchema} from "./validation";

export const DocEdit = (): React.ReactElement => {
    const {id} = useParams();
    const {handleSubmit, record} = useUpdateDoc({
        id,
        // onSuccess: () => redirect('/docs')
    })
    return (
        <>
            <Title title="resources.docs.edit.title"/>
            <SimpleForm record={record} onSubmit={handleSubmit} resolver={updateDocSchema}>
                <DocEditFields/>
            </SimpleForm>
        </>
    );
};
