import {
    DateInput,
    SaveButton,
    SimpleForm,
    TextInput,
    useEditController,
    useNotify,
    useTranslate,
    useUpdate,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
} from "react-admin";
import * as React from "react";
import {useCallback} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {yupResolver} from "@hookform/resolvers/yup";
import {schema} from "../../personAddress/validation";
import Grid from "@mui/material/Grid/Grid";
import {CountryTitle} from "../../country/CountryTitle";
import {StateTitle} from "../../state/StateTitle";
import {CommonTitle} from "../../common/CommonTitle";
import {CityTitle} from "../../city/CityTitle";
import {DistrictTitle} from "../../district/DistrictTitle";
import {StreetTitle} from "../../street/StreetTitle";
import {PersonTitle} from "../PersonTitle";

const resolver = yupResolver(schema);

interface Props {
    onClose: () => void;
    id: string
    open: boolean
}

const renderChildren: React.FC = ({children}) => <>{children}</>;

export const PersonAddressesEditDialog = (props: Props) => {
    const {record, save} = useEditController({
        resource: 'PersonAddress',
        id: props.id,
        redirect: false,
    });
    const translate = useTranslate();
    const [update] = useUpdate();
    const notify = useNotify();
    const handleSubmit = useCallback((values) => {
        update('PersonAddress', {id: props.id, data: values});
        save!(values, {
            onSuccess: data => {
                props.onClose();
                notify('ra.notification.edited', {
                    type: 'info',
                    messageArgs: {smart_count: 1},
                });
            }
        })
    }, [props.id]);

    return <Dialog
        open={props.open}
        onClose={props.onClose}>
        <SimpleForm
            resolver={resolver}
            reValidateMode="onChange"
            record={record}
            toolbar={false}
            component={renderChildren}
            onSubmit={handleSubmit}>
            <DialogTitle>{translate('resources.personAddresses.action.edit')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={'auto'}>
                        <ReferenceInput source="person.id" reference="Person">
                            <SelectInput
                                label="resources.personAddresses.fields.person"
                                optionText={PersonTitle}
                                disabled
                                fullWidth
                            />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12}/>
                    <Grid item xs={12} md={'auto'}>
                        <ReferenceInput
                            source="country.id"
                            reference="Country">
                            <SelectInput
                                optionText={CountryTitle} fullWidth
                                label="resources.personAddresses.fields.country"/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <ReferenceInput source="state.id" reference="State">
                            <SelectInput
                                optionText={StateTitle} fullWidth
                                label="resources.personAddresses.fields.state"/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <ReferenceInput source="common.id" reference="Common">
                            <SelectInput
                                optionText={CommonTitle} fullWidth
                                label="resources.personAddresses.fields.common"/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <ReferenceInput source="city.id" reference="City">
                            <AutocompleteInput
                                label="resources.personAddresses.fields.city"
                                filterToQuery={searchText => ({label: {contains: searchText, mode: 'Insensitive'}})}
                                sx={{marginTop: 1}}
                                size={'small'}
                                fullWidth
                                optionText={CityTitle}/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <ReferenceInput
                            source="district.id"
                            reference="District">
                            <AutocompleteInput
                                label="resources.personAddresses.fields.district"
                                filterToQuery={searchText => ({label: {contains: searchText, mode: 'Insensitive'}})}
                                sx={{marginTop: 1}}
                                size={'small'}
                                fullWidth
                                optionText={DistrictTitle}/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <ReferenceInput source="street.id" reference="Street">
                            <AutocompleteInput
                                label="resources.personAddresses.fields.street"
                                filterToQuery={searchText => ({label: {contains: searchText, mode: 'Insensitive'}})}
                                sx={{marginTop: 1}}
                                size={'small'}
                                fullWidth
                                optionText={StreetTitle}/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12}/>
                    <Grid item xs={12} md={'auto'}>
                        <TextInput label="resources.personAddresses.fields.house" source="house" fullWidth/>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <TextInput label="resources.personAddresses.fields.apartment" source="appartment" fullWidth/>
                    </Grid>
                    <Grid item xs={12} md={'auto'}/>
                    <Grid item xs={12} md={'auto'}>
                        <DateInput label="resources.personAddresses.fields.startDate" source="startDate" fullWidth/>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <DateInput label="resources.personAddresses.fields.endDate" source="endDate" fullWidth/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{translate('resources.contacts.action.cancel')}</Button>
                <SaveButton
                    label="resources.contacts.action.save"
                    variant="text"
                />
            </DialogActions>
        </SimpleForm>
    </Dialog>
}