import * as React from "react";
import {
    List,
    Datagrid,
    ListProps,
    DateField,
    TextField,
    ReferenceField, ImageField,
} from "react-admin";
import Pagination from "../Components/Pagination";
import {PERSON_TITLE_FIELD} from "../person/PersonTitle";

export const FileList = (props: ListProps): React.ReactElement => {
    return (
        <List
            {...props}
            title={"Files"}
            perPage={50}
            pagination={<Pagination/>}
        >
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <DateField source="createdAt" label="resources.files.fields.createdAt"/>
                <TextField label="ID" source="id"/>
                <TextField label="resources.files.fields.info" source="info"/>
                <ImageField
                    label="resources.files.fields.path"
                    source="path"
                />
                <ReferenceField label="resources.files.fields.person" source="person.id" reference="Person">
                    <TextField source={PERSON_TITLE_FIELD}/>
                </ReferenceField>
                <DateField source="updatedAt" label="resources.files.fields.updatedAt"/>
            </Datagrid>
        </List>
    );
};
