import * as React from "react";
import {
  List,
  Datagrid,
  ListProps,
  DateField,
  TextField,
  ReferenceField,
} from "react-admin";
import Pagination from "../Components/Pagination";
import { STATE_TITLE_FIELD } from "../state/StateTitle";

export const CommonList = (props: ListProps): React.ReactElement => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Commons"}
      perPage={50}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show">
        <DateField source="createdAt" label="resources.commons.fields.createdAt" />
        <TextField label="ID" source="id" />
        <TextField label="resources.commons.fields.label" source="label" />
        <TextField label="Slug" source="slug" />
        <ReferenceField label="resources.commons.fields.state" source="state.id" reference="State">
          <TextField source={STATE_TITLE_FIELD} />
        </ReferenceField>
        <DateField source="updatedAt" label="resources.commons.fields.updatedAt" />
      </Datagrid>
    </List>
  );
};
