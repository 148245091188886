import {
    Confirm,
    Datagrid,
    DateField, Identifier,
    ReferenceField,
    ReferenceManyField, SelectField, SimpleList,
    TextField, useCreatePath,
    useRecordContext,
    useTranslate,
} from "react-admin";
import * as React from "react";
import {CITY_TITLE_FIELD} from "../../city/CityTitle";
import {COMMON_TITLE_FIELD} from "../../common/CommonTitle";
import {COUNTRY_TITLE_FIELD} from "../../country/CountryTitle";
import {DISTRICT_TITLE_FIELD} from "../../district/DistrictTitle";
import {STATE_TITLE_FIELD} from "../../state/StateTitle";
import {STREET_TITLE_FIELD} from "../../street/StreetTitle";
import {DataGridHeader} from "../components/DataGridHeader";
import {DataGridBody} from "../components/DataGridBody";
import {useEditDialog} from "./useEditDialog";
import {PersonAddressesEditDialog} from "../components/PersonAddressesEditDialog";
import Typography from "@mui/material/Typography/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import AddIcon from '@mui/icons-material/Add';
import {PersonAddressesCreateDialog} from "../components/PersonAddressesCreateDialog";
import {useDeleteConfirm} from "./useDeleteConfirm";
import {ListIconWrapper} from "../components/ListIconWrapper";
import {contactTypeChoices} from "../../contsatns/common";
import {useCallback} from "react";
import {RaRecord} from "ra-core";
import {Link as RouterLink} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {useListRightIcon} from "./useListRightIcon";

interface Props {
    isMobile?: boolean;
}

export const PersonAddresses = ({isMobile}: Props) => {
    const {id} = useRecordContext();
    const {
        closeEditDialog,
        openEditDialog,
        isOpenEditDialog,
        closeCreateDialog,
        openCreateDialog,
        isOpenCreateDialog,
        editedId,
    } = useEditDialog();
    const {
        closeDeleteDialog,
        confirmDelete,
        isOpenDeleteDialog,
        deleteEntity
    } = useDeleteConfirm('PersonAddress');
    const translate = useTranslate();
    const rightIcon = useListRightIcon('PersonAddress');
    return <>
        <Confirm
            isOpen={isOpenDeleteDialog}
            title={`ra.message.delete_conf_title`}
            content={`ra.message.delete_content`}
            onConfirm={confirmDelete}
            onClose={closeDeleteDialog}
        />
        <Stack direction="row" spacing={1}>
            <Typography variant="h6">{translate('resources.people.create.title31')}</Typography>
            <IconButton size={'small'} onClick={openCreateDialog}>
                <AddIcon/>
            </IconButton>
        </Stack>
        <ReferenceManyField reference="PersonAddress" target="person">
            {
                isMobile ? <ListIconWrapper>
                        <SimpleList
                            linkType={false}
                            primaryText={<>
                                <ReferenceField
                                    link={false}
                                    source="city.id"
                                    reference="City">
                                    <TextField source={CITY_TITLE_FIELD}/>
                                </ReferenceField>{', '}
                                <ReferenceField
                                    link={false}
                                    source="street.id"
                                    reference="Street">
                                    <TextField source={STREET_TITLE_FIELD}/>
                                </ReferenceField>{', буд. '}
                                {/*todo: fix localisation ', буд. ' */}
                                <TextField
                                    label={translate('resources.personAddresses.fields.house')}
                                    source="house"/>{', кв. '}
                                {/*todo: fix localisation ', кв. ' */}
                                <TextField
                                    emptyText={'-'}
                                    label={translate('resources.personAddresses.fields.apartment')}
                                    source="appartment"/>
                            </>}
                            rightIcon={rightIcon}
                        />
                    </ListIconWrapper> :
                    <Datagrid
                        header={<DataGridHeader/>}
                        body={<DataGridBody onEditClick={openEditDialog} onDeleteClick={deleteEntity}/>}
                    >
                        <ReferenceField
                            label={translate('resources.personAddresses.fields.city')}
                            source="city.id"
                            reference="City">
                            <TextField source={CITY_TITLE_FIELD}/>
                        </ReferenceField>
                        <ReferenceField
                            label={translate('resources.personAddresses.fields.district')}
                            source="district.id"
                            reference="District"
                        >
                            <TextField source={DISTRICT_TITLE_FIELD}/>
                        </ReferenceField>
                        <ReferenceField
                            label={translate('resources.personAddresses.fields.street')}
                            source="street.id"
                            reference="Street">
                            <TextField source={STREET_TITLE_FIELD}/>
                        </ReferenceField>
                        <TextField
                            label={translate('resources.personAddresses.fields.house')}
                            source="house"/>
                        <TextField
                            label={translate('resources.personAddresses.fields.apartment')}
                            source="appartment"/>
                        <ReferenceField
                            label={translate('resources.personAddresses.fields.common')}
                            source="common.id"
                            reference="Common">
                            <TextField source={COMMON_TITLE_FIELD}/>
                        </ReferenceField>
                        <ReferenceField
                            label={translate('resources.personAddresses.fields.state')}
                            source="state.id"
                            reference="State">
                            <TextField source={STATE_TITLE_FIELD}/>
                        </ReferenceField>
                        <ReferenceField
                            label={translate('resources.personAddresses.fields.country')}
                            source="country.id"
                            reference="Country">
                            <TextField source={COUNTRY_TITLE_FIELD}/>
                        </ReferenceField>
                        <DateField
                            label={translate('resources.personAddresses.fields.startDate')}
                            source="startDate"/>
                        <DateField
                            label={translate('resources.personAddresses.fields.endDate')}
                            source="endDate"/>
                    </Datagrid>
            }
        </ReferenceManyField>
        {
            editedId &&
            <PersonAddressesEditDialog
                id={editedId}
                onClose={closeEditDialog}
                open={isOpenEditDialog}
            />
        }
        <PersonAddressesCreateDialog
            onClose={closeCreateDialog}
            open={isOpenCreateDialog}
            personId={id}
        />
    </>
}