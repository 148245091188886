import * as React from "react";

import {
    Edit,
    SimpleForm,
    EditProps,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceInput,
    SelectInput, AutocompleteInput, AutocompleteArrayInput,
} from "react-admin";

import {PersonTitle} from "../person/PersonTitle";
import {FamilyStatusTitle} from "../familyStatus/FamilyStatusTitle";
import {CreateFamilyDialog} from "./CreateFamilyDialog";
import {FamilyTitle} from "./FamilyTitle";

export const FamilyEdit = (props: EditProps): React.ReactElement => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput label="Label" source="label"/>
                <ReferenceInput
                    source="peopleIds"
                    reference="Person">
                    <AutocompleteArrayInput
                        label="resources.people.fields.people"
                        filterToQuery={searchText => ({label: {contains: searchText, mode: 'Insensitive'}})}
                        sx={{marginTop: 0}}
                        size={'small'}
                        fullWidth
                        optionText={PersonTitle}
                    />
                </ReferenceInput>

                {/*<ReferenceArrayInput*/}
                {/*    source="people"*/}
                {/*    reference="Person"*/}
                {/*    parse={(value: any) => value && value.map((v: any) => ({id: v}))}*/}
                {/*    format={(value: any) => value && value.map((v: any) => v.id)}*/}
                {/*>*/}
                {/*    <SelectArrayInput optionText={PersonTitle}/>*/}
                {/*</ReferenceArrayInput>*/}
                <ReferenceInput
                    source="status.id"
                    reference="FamilyStatus"
                    label="Status"
                >
                    <SelectInput optionText={FamilyStatusTitle}/>
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};
