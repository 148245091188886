import {useFileUpload} from "../hooks/useFileUpload";
import {useCreate, UseCreateOptions} from "react-admin";

export const useCreateAction = ({onSuccess}: UseCreateOptions) => {
    const [create] = useCreate();
    const {mutateAsync} = useFileUpload();

    const handleSubmit = async ({_path, ...values}: any) => {
        console.log('values', values)
        await mutateAsync(_path.rawFile, {
            onSuccess: async ({data}) =>
                await create('Action', {
                        data: {
                            ...values,
                            file: {id: data.id}
                        }
                    },
                    {
                        onSuccess
                    }
                )
        });
    }
    return {
        handleSubmit,
    }
}