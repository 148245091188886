import * as React from "react";

import {
  Create,
  SimpleForm,
  CreateProps,
  ReferenceInput,
  SelectInput,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";

import { CityTitle } from "../city/CityTitle";
import { PersonAddressTitle } from "../personAddress/PersonAddressTitle";
import { StreetTitle } from "../street/StreetTitle";
import {createTransformFn} from "../util/createTransformFn";
import {District} from "../api/district/District";
import {createSchema} from "./validation";

const transform = createTransformFn<District>(['streets', 'personAddresses']);

export const DistrictCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props} transform={transform} redirect={'list'}>
      <SimpleForm resolver={createSchema}>
        <ReferenceInput source="city.id" reference="City" label="City">
          <SelectInput optionText={CityTitle} label="resources.districts.fields.city"/>
        </ReferenceInput>
        <TextInput label="Label" source="label" />
        <ReferenceArrayInput
          source="personAddresses"
          reference="PersonAddress"
          parse={(value: any) => value && value.map((v: any) => ({ id: v }))}
          format={(value: any) => value && value.map((v: any) => v.id)}
        >
          <SelectArrayInput optionText={PersonAddressTitle} label="resources.districts.fields.Email"/>
        </ReferenceArrayInput>
        <TextInput label="Slug" source="slug" />
        <ReferenceArrayInput
          source="streets"
          reference="Street"
          parse={(value: any) => value && value.map((v: any) => ({ id: v }))}
          format={(value: any) => value && value.map((v: any) => v.id)}
        >
          <SelectArrayInput optionText={StreetTitle} label="resources.districts.fields.streets"/>
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
