import {ReferenceField, TextField, useEditContext, useGetManyReference, useTranslate} from "react-admin";
import * as React from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {timelineOppositeContentClasses,} from '@mui/lab/TimelineOppositeContent';
import {Action} from "../../api/action/Action";
import Typography from "@mui/material/Typography/Typography";
import {EmptyTitleDataGrid} from "../../Components/EmptyTitleDataGrid";
import Box from "@mui/material/Box/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import {useEditDialog} from "./useEditDialog";
import {ActionsCreateDialog} from "../components/ActionsCreateDialog";

const SimpleDate = React.memo(({date}: { date: Date | null }) => <>{date && new Date(date).toLocaleDateString('uk-UA')}</>);
export const Actions = () => {
    const {record} = useEditContext();
    const {
        closeEditDialog,
        openEditDialog,
        isOpenEditDialog,
        closeCreateDialog,
        openCreateDialog,
        isOpenCreateDialog,
        editedId,
    } = useEditDialog();
    const {data} = useGetManyReference('Action', {
        target: 'person',
        id: record.id,
        sort: {field: 'date', order: 'DESC'},
        pagination: {page: 1, perPage: 100}
    }, {
        enabled: !!record.actionsIds,
    });
    const translate = useTranslate()
    return (
        <>
            <Stack direction="row" spacing={1}>
                <Typography variant={'h6'}>{translate('resources.people.fields.actions')}</Typography>
                <IconButton size={'small'} onClick={openCreateDialog}>
                    <AddIcon/>
                </IconButton>
            </Stack>
            {
                !record.actions?.length &&
                <Box sx={{p: 2,}}>
                    <EmptyTitleDataGrid/>
                </Box>
            }
            {
                data && <Timeline
                    sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                            flex: 0.2,
                        },
                    }}
                >
                    {
                        data.map((action: Action, index) => (
                            <TimelineItem key={action.id}>
                                <TimelineOppositeContent color="textSecondary">
                                    <SimpleDate date={action.date}/>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot variant="outlined" color={'info'}/>
                                    {
                                        index !== data.length - 1 &&
                                        <TimelineConnector/>
                                    }
                                </TimelineSeparator>
                                <TimelineContent>
                                    <ReferenceField
                                        source={'actionType.id'}
                                        reference={'ActionType'}
                                        record={action}
                                        link={false}>
                                        <TextField
                                            variant={'subtitle1'}
                                            component={Typography}
                                            source={'label'}/>
                                    </ReferenceField>
                                    <Typography variant={'subtitle2'}
                                                color={'text.secondary'}>{action.description}</Typography>
                                </TimelineContent>
                            </TimelineItem>
                        ))
                    }
                </Timeline>
            }
            <ActionsCreateDialog
                onClose={closeCreateDialog}
                open={isOpenCreateDialog}
                personId={record.id}
            />
        </>
    );
}