import * as React from "react";
import {
  List,
  Datagrid,
  ListProps,
  ReferenceField,
  TextField,
  DateField,
} from "react-admin";
import Pagination from "../Components/Pagination";
import { COMMON_TITLE_FIELD } from "../common/CommonTitle";

export const CityList = (props: ListProps): React.ReactElement => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Cities"}
      perPage={50}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show">
        <ReferenceField label="resources.cities.fields.common" source="common.id" reference="Common">
          <TextField source={COMMON_TITLE_FIELD} />
        </ReferenceField>
        <DateField source="createdAt" label="resources.cities.fields.createdAt" />
        <TextField label="ID" source="id" />
        <TextField label="resources.cities.fields.label" source="label" />
        <TextField label="Slug" source="slug" />
        <DateField source="updatedAt" label="resources.cities.fields.updatedAt" />
      </Datagrid>
    </List>
  );
};
