import * as yup from "yup";
import {Common} from "../api/common/Common";
import {yupResolver} from "@hookform/resolvers/yup";

export const createSchema = yupResolver(yup
    .object<Common>()
    .shape({
        label: yup.string().required(),
        slug: yup.string().required(),
        // personAddresses: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
        // cities: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
        state: yup.object({
            id: yup.string().required(),
        }),
    })
    .required()
);
