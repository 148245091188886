import * as yup from "yup";
import {Country} from "../api/country/Country";
import {yupResolver} from "@hookform/resolvers/yup";

export const createSchema = yupResolver(yup
    .object<Country>()
    .shape({
        label: yup.string().required(),
        slug: yup.string().required(),
        // states: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
        // personAddresses: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
    })
    .required()
);
