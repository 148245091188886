import * as React from "react";

import {
  Show,
  SimpleShowLayout,
  ShowProps,
  DateField,
  TextField,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
} from "react-admin";

import { FAMILYSTATUS_TITLE_FIELD } from "./FamilyStatusTitle";

export const FamilyStatusShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DateField source="createdAt" label="resources.familyStatuses.fields.createdAt" />
        <TextField label="ID" source="id" />
        <TextField label="resources.familyStatuses.fields.label" source="label" />
        <TextField label="Slug" source="slug" />
        <DateField source="updatedAt" label="resources.familyStatuses.fields.updatedAt" />
        <ReferenceManyField
          reference="Family"
          target="FamilyStatusId"
          label="resources.familyStatuses.fields.family"
        >
          <Datagrid rowClick="show">
            <DateField source="createdAt" label="resources.familyStatuses.fields.createdAt" />
            <TextField label="ID" source="id" />
            <TextField label="resources.familyStatuses.fields.label" source="label" />
            <ReferenceField
              label="resources.familyStatuses.fields.familystatus"
              source="familystatus.id"
              reference="FamilyStatus"
            >
              <TextField source={FAMILYSTATUS_TITLE_FIELD} />
            </ReferenceField>
            <DateField source="updatedAt" label="resources.familyStatuses.fields.updatedAt" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
