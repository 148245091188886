import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material/styles';
import {Typography} from "@mui/material";
// import logo from '../assets/logo.jpg';
const Logo = (props: SVGProps<SVGSVGElement>) => {
    const theme = useTheme();
    return (
        // <img src={'../assets/logo.jpg'} alt="Слобода UA"/> //todo
        <Typography variant="h6" color="inherit" >Слобода UA</Typography>
    );
};

export default Logo;
