import {useFileUpload} from "../hooks/useFileUpload";
import {useCreate, UseCreateOptions} from "react-admin";

export const useCreateDoc = ({onSuccess}: UseCreateOptions) => {
    const {mutateAsync} = useFileUpload();
    const [create] = useCreate();
    const handleSubmit = async ({_path, ...values}: any) => {
        await mutateAsync(_path.rawFile, {
            onSuccess: async ({data}) =>
                await create('Doc', {
                        data: {
                            ...values,
                            file: {id: data.id}
                        }
                    },
                    {
                        onSuccess
                    }
                )
        });
    }
    return {
        handleSubmit,
    }
}