import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {State} from "../api/state/State";

export const createSchema = yupResolver(yup
    .object<State>()
    .shape({
        label: yup.string().required(),
        slug: yup.string().required(),
        // commons: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
        country: yup.object({
            id: yup.string().required(),
        }),
        // personAddresses: yup.array().of(yup.object({
        //     id: yup.string().required(),
        // })),
    })
    .required()
);
