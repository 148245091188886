import * as React from "react";

import {
    Edit,
    SimpleForm,
    EditProps,
    ReferenceArrayInput,
    SelectArrayInput,
    TextInput,
    PasswordInput, useTranslate,
} from "react-admin";

import {ActionTitle} from "../action/ActionTitle";
import {ROLES_OPTIONS} from "../user/RolesOptions";

export const UserEdit = (props: EditProps): React.ReactElement => {
    const translate = useTranslate();
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceArrayInput
                    source="actions"
                    reference="Action"
                    parse={(value: any) => value && value.map((v: any) => ({id: v}))}
                    format={(value: any) => value && value.map((v: any) => v.id)}
                >
                    <SelectArrayInput optionText={ActionTitle}/>
                </ReferenceArrayInput>
                <TextInput label={translate('resources.users.fields.firstName')} source="firstName"/>
                <TextInput label={translate('resources.users.fields.lastName')} source="lastName"/>
                <PasswordInput label={translate('resources.users.fields.password')} source="password"/>
                <SelectArrayInput
                    label={translate('resources.users.fields.roles')}
                    source="roles"
                    choices={ROLES_OPTIONS}
                    optionText="label"
                    optionValue="value"
                />
                <TextInput label={translate('resources.users.fields.username')} source="username"/>
            </SimpleForm>
        </Edit>
    );
};
