import Icon from '@mui/icons-material/TypeSpecimen';
import {ActionTypeList} from "./ActionTypeList";
import {ActionTypeCreate} from "./ActionTypeCreate";
import {ActionTypeEdit} from "./ActionTypeEdit";
import {ActionTypeShow} from "./ActionTypeShow";

export default {
    name: 'ActionType',
    icon: Icon,
    list: ActionTypeList,
    create: ActionTypeCreate,
    edit: ActionTypeEdit,
    show: ActionTypeShow,
    options: {
        label: 'resources.actionTypes.name'
    },
};
