import Icon from '@mui/icons-material/History';
import {ActionList} from "./ActionList";
import {ActionCreate} from "./ActionCreate";
import {ActionEdit} from "./ActionEdit";
import {ActionShow} from "./ActionShow";

export default {
    name: 'Action',
    icon: Icon,
    list: ActionList,
    create: ActionCreate,
    edit: ActionEdit,
    show: ActionShow,
    options: {
        label: 'resources.actions.name'
    },
};
