import PublicIcon from '@mui/icons-material/Public';
import {CountryList} from "./CountryList";
import {CountryCreate} from "./CountryCreate";
import {CountryEdit} from "./CountryEdit";
import {CountryShow} from "./CountryShow";

export default {
    name: 'Country',
    icon: PublicIcon,
    list: CountryList,
    create: CountryCreate,
    edit: CountryEdit,
    show: CountryShow,
    options: {
        label: 'resources.countries.name'
    },
};
