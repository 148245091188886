import {defaultTheme} from "react-admin";
import createPalette from "@mui/material/styles/createPalette";
import {createTheme, ThemeOptions} from "@mui/material";
import {blue, purple, yellow} from "@mui/material/colors";

const palette = createPalette({
    ...defaultTheme.palette,
    primary: {
        main: '#00796B',
    },
    secondary: {
        main: '#607D8B',
    },
    // primary: {
    //     main: "#20a4f3",
    // },
    // secondary: {
    //     // main: "#7950ed",
    //     main: blue[500],
    // },
    // error: {
    //     main: "#e93c51",
    // },
    // warning: {
    //     main: "#f6aa50",
    // },
    // info: {
    //     main: "#144bc1",
    // },
    // success: {
    //     main: "#31c587",
    // },

});


export const lightTheme: ThemeOptions = createTheme({
    ...defaultTheme,
    palette,
    components: {
        ...defaultTheme.components,
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'outlined',
            },
        },
    }

});


export const darkTheme = createTheme({
    ...defaultTheme,
    ...{
        palette: createPalette({
            // ...defaultTheme.palette,
            mode: 'dark',
            primary: {
                main: '#00796B',
            },
            secondary: {
                main: '#607D8B',
            },

        }),
        components: {
            ...defaultTheme.components,
            MuiTextField: {
                defaultProps: {
                    variant: 'outlined',
                },
            },
            MuiFormControl: {
                defaultProps: {
                    variant: 'outlined',
                },
            },
        }
    }
});
