import * as yup from "yup";
import {PersonAddress} from "../api/personAddress/PersonAddress";

export const schema = yup
    .object<PersonAddress>()
    .shape({
        house: yup.string().required(),
        appartment: yup.string().optional().nullable(),
        person: yup.object().shape({
            id: yup.string().required()
        }),
        city: yup.object().shape({
            id: yup.string().required()
        }),
        district: yup.object().shape({
            id: yup.string().required()
        }),
        street: yup.object().shape({
            id: yup.string().required()
        }),
        common: yup.object().shape({
            id: yup.string().required()
        }),
        state: yup.object().shape({
            id: yup.string().required()
        }),
        country: yup.object().shape({
            id: yup.string().required()
        }),
        startDate: yup.date().required(),
        endDate: yup.date().optional().nullable(),
    })
    .required();
