import {FC} from "react";
import Box from "@mui/material/Box/Box";

export const ListIconWrapper: FC = ({children}) => {
    return <Box
        sx={{ // TODO: this is a hack, fix it if possible
            '& .MuiListItemIcon-root': {
                minWidth: 0,
            },
            '& .MuiListItemSecondaryAction-root': {
                right: 0,
            }
        }}
    >{children}</Box>
}