import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import {FamilyList} from "./FamilyList";
import {FamilyCreate} from "./FamilyCreate";
import {FamilyEdit} from "./FamilyEdit";
import {FamilyShow} from "./FamilyShow";

export default {
    name: 'Family',
    icon: FamilyRestroomIcon,
    list: FamilyList,
    create: FamilyCreate,
    edit: FamilyEdit,
    show: FamilyShow,
    options: {
        label: 'resources.families.name'
    },
};
