import * as React from "react";
import {
    DateInput, DateTimeInput,
    Identifier,
    ImageField,
    ImageInput,
    ReferenceArrayInput,
    ReferenceInput, RichTextField, SelectArrayInput,
    SelectInput,
    TextInput,
} from "react-admin";
import {DocTypeTitle} from "../docType/DocTypeTitle";
import {PersonTitle} from "../person/PersonTitle";
import {ActionTypeTitle} from "../actionType/ActionTypeTitle";
import {FileTitle} from "../file/FileTitle";
import {UserTitle} from "../user/UserTitle";
import {RichTextInput} from 'ra-input-rich-text';
import {useFileSizeValidation} from "../hooks/useFileSizeValidation";

interface Props {
    personId?: Identifier
    isDialog?: boolean
}

export const ActionCreateFields: React.FC<Props> = ({personId, isDialog}) => {
    const fileSizeValidation = useFileSizeValidation();
    return (
        <>
            <ReferenceInput source="person.id" reference="Person">
                <SelectInput optionText={PersonTitle} disabled={!!personId} fullWidth={isDialog}
                             label="resources.actions.fields.person"/>
            </ReferenceInput>
            <ReferenceInput
                source="actionType.id"
                reference="ActionType">
                <SelectInput optionText={ActionTypeTitle} fullWidth={isDialog}
                             label="resources.actions.fields.actionType"/>
            </ReferenceInput>
            <DateTimeInput label="resources.actions.fields.date" source="date" fullWidth={isDialog}/>
            <TextInput label="resources.actions.fields.description"
                       multiline source="description" fullWidth={isDialog}
                       resettable/>
            {/*<ReferenceArrayInput
                source="photos"
                reference="File"
                parse={(value: any) => value && value.map((v: any) => ({id: v}))}
                format={(value: any) => value && value.map((v: any) => v.id)}>
                <SelectArrayInput optionText={FileTitle} fullWidth={isDialog} label="resources.actions.fields.photos"/>
            </ReferenceArrayInput>*/}
            <ImageInput
                source="_path"
                label={'resources.actions.fields.photos'}
                fullWidth={isDialog}
                {...fileSizeValidation}
            >
                <ImageField source="src" label={'resources.actions.fields.photos'}/>
            </ImageInput>
            <ReferenceInput source="user.id" reference="User">
                <SelectInput optionText={UserTitle} fullWidth={isDialog} label="resources.actions.fields.user"/>
            </ReferenceInput>
        </>
    );
};
