import * as React from "react";
import {
  List,
  Datagrid,
  ListProps,
  TextField,
  DateField,
  ReferenceField,
} from "react-admin";
import Pagination from "../Components/Pagination";
import { PERSON_TITLE_FIELD } from "../person/PersonTitle";

export const ContactList = (props: ListProps): React.ReactElement => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Contacts"}
      perPage={50}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show">
        <TextField label="resources.contacts.fields.contactType" source="contactType" />
        <DateField source="createdAt" label="resources.contacts.fields.createdAt" />
        <TextField label="ID" source="id" />
        <ReferenceField label="resources.contacts.fields.person" source="person.id" reference="Person">
          <TextField source={PERSON_TITLE_FIELD} />
        </ReferenceField>
        <DateField source="updatedAt" label="resources.contacts.fields.updatedAt" />
        <TextField label="resources.contacts.fields.value" source="value" />
      </Datagrid>
    </List>
  );
};
