import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

export const createActionSchema = yupResolver(yup.object().shape({
    description: yup.string().required(),
    actionType: yup.object().shape({
        id: yup.string().required(),
    }).required(),
    person: yup.object().shape({
        id: yup.string().required(),
    }).required(),
}));

export const updateActionSchema = yupResolver(yup.object().shape({}));
